// component
const component = {
	name: 'SclAlert',
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.icon ? typeof obj.icon === 'string' : true));
				set.add(!!(obj.type ? typeof obj.type === 'string' : true));
				set.add(!!(obj.dismissible ? typeof obj.dismissible === 'boolean' : true));
				set.add(!!(obj.outline ? typeof obj.outline === 'boolean' : true));
				return !set.has(false);
			}
		},
		id: {
			type: String
		}
	},
	data() {
		return {
			model: true
		};
	},
	mounted() {
		this.handleCookieStatus();
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					icon: null,
					type: 'info',
					dismissible: false,
					outline: false
				},
				this.api
			);
		},
		isDismissed() {
			return this.model;
		}
	},
	methods: {
		handleCookieStatus() {
			const cookieName = this.id;
			const cookies = document.cookie.split(';');
			for (var i = 0; i < cookies.length; i++) {
				let item = cookies[i];
				while (item.charAt(0) == ' ') {
					item = item.substring(1, item.length);
				}
				if (item.indexOf(cookieName) == 0) {
					this.model = false;
				}
			}
		},
		dismiss() {
			const now = new Date();
			const minutes = 30;
			const cookievalue = this.id;
			now.setTime(now.getTime() + minutes * 60 * 1000);
			document.cookie = `${cookievalue}=${cookievalue}; expires=${now}; path=/`;
			this.$root.setHeight();
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT) {
	component.template = require(`./${component.name}-template`).default;
}

// css import
import(`./_${component.name}.scss`);

// export component
export default component;
