/**
 * SclBranchMap
 */

const template = `
	<div>
		<GmapMap
			:center="latlng"
			:zoom="16"
			class="scl-branch-detail__map"
			:options="{
				zoomControl: false,
				mapTypeControl: false,
				scaleControl: false,
				streetViewControl: false,
				rotateControl: false,
				fullscreenControl: false,
				disableDefaultUi: false
			}"
		>
			<!-- Computed -->
			<GmapMarker
				:position="latlng"
				:clickable="true"
				:draggable="true"
				:icon="{ scaledSize: { width: 35, height: 45 }, url: '/assets/img/map-marker.svg' }"
			/>
		</GmapMap>
	</div>
`;

export default template;
