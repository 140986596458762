// component
const component = {
	name: 'SclBannerDescriptor',
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.bgImage ? typeof obj.bgImage === 'string' : true));
				set.add(!!(obj.imageTagText ? typeof obj.imageTagText === 'string' : true));
				set.add(!!(obj.headerText ? typeof obj.headerText === 'string' : true));
				set.add(!!(obj.descriptorText ? typeof obj.descriptorText === 'string' : true));
				set.add(!!(obj.dropDownSelector ? typeof obj.dropDownSelector === 'object' : true));
				set.add(!!(obj.tertiaryCta ? typeof obj.tertiaryCta === 'string' : true));
				return !set.has(false);
			}
		}
	},
	data() {
		return {
			selectedLink: null
		};
	},
	mounted() {
		this.selectedLink = this.propApi.dropDownSelector[0].primaryCta;
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					bgImage: '',
					imageTagText: '',
					headerText: '',
					descriptorText: '',
					dropDownSelector: [],
					tertiaryCta: ''
				},
				this.api
			);
		}
	},
	methods: {
		selectChange(e) {
			this.selectedLink = e;
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT) {
	component.template = require(`./${component.name}-template`).default;
}

// css import
import(`./_${component.name}.scss`);

// export component
export default component;
