/**
 * SclMap api
 */

// template
const template = `
	<div :class="{'is-visible' : hasSidebar}">
		<div class="navigation">
			<span class="prev" :class="{ 'disable': prevNavDisabled }" @click="goToPrev"><v-icon size="60">fas fa-chevron-left</v-icon></span>
			<span class="next" :class="{ 'disable': nextNavDisabled }" @click="goToNext"><v-icon size="60">fas fa-chevron-right</v-icon></span>
		</div>
		<v-container>
			<v-layout row wrap>
				<div class="scl-map__container" :style="{ width: slideWidth }">				
					<h5 class="scl-map__branch-branches">{{ filteredBranches.length }} branches found near your location</h5>
					<div class="scl-map__sidebar" :style="{ width: innerWidth, marginLeft: '-' + slidesInnerMarginLeft + 'px' }">
						<div :style="{ width: singleSlideWidth }" 
							v-for="(branch, index) in filteredBranches" 
							:key="branch.StoreCode" class="scl-map__sidebar-item"
						>
							<GmapMap
								:center="branch.position"
								:zoom="zoom"
								map-type-id="roadmap"
								style="height: 150px"
								:options="{
									zoomControl: false,
									mapTypeControl: false,
									scaleControl: false,
									streetViewControl: false,
									rotateControl: false,
									fullscreenControl: false,
									disableDefaultUi: false,
									gestureHandling: 'none',
									clickableIcons: false
								}"
							>
								<gmap-marker
									:icon="{ scaledSize: { width: 21, height: 27 }, url: '/assets/img/map-marker.svg' }"
									:key="branch.StoreCode"
									:position="branch.position"
									:clickable="false"
								/>
								</gmap-marker>
							</GmapMap>	
							<ul class="scl-map__branch-list">
								<li><h5 class="scl-map__branch-title">{{ branch.StoreName }}</h5></li>
								<li><b class="scl-map__branch-type">{{ branch.StoreType}}</b></li>
								<li class="address">{{ branch.StoreAddress }} <br />
									{{ branch.StoreSuburb }} {{ branch.StoreState }} {{ branch.StorePostCode }}
								</li>
								<li class="phone"><v-icon>fas fa-phone-alt</v-icon><a :href="'tel:' + branch.StorePhone">{{ branch.StorePhone }}</a></li>
								<li class="email"><v-icon>fas fa-envelope</v-icon><a :href="'mailto:' + branch.StoreEmail">{{ branch.StoreEmail }}</a></li>
								<li>
									<v-layout row justify-space-around>
										<a class="scl-header-large__nav-btn mr-3" :href="branch.directions" target="_blank">Directions</a>									
										<a class="scl-header-large__nav-btn mr-3" :href="branch.StorePageUrl">Store details</a>
									</v-layout>	
								</li>
							</ul>
						</div>
					</div>
					<GmapMap style="display: none;"
						:center="map.mapCenter"
						:zoom="map.mapZoom"
						class="scl-map__map"
						ref="mapRef"
					>
					</GmapMap>
				</div>
			</v-layout>
		</v-container>
	</div>
`;

// export template
export default template;
