/**
 * Scl2Col8
 */

// template
const template = `
	<scl-2-Col-8 inline-template class="scl-component scl-2-col-8">
		<v-container grid-list-lg>
			<v-layout row wrap>
				<v-flex xs12 md8>
					<scl-dummy-data :content="$root.dummyParagraph"></scl-dummy-data>
				</v-flex>
				<v-flex xs12 md4>
					<scl-dummy-data :content="$root.dummyParagraph"></scl-dummy-data>
				</v-flex>
			</v-layout>
		</v-container>
	</scl-2-Col-8>
`;

// export template
export default template;
