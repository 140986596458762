/**
 * SclCardCtaEnhanced api
 *
 */

// template
const template = `
	<div>
		<!-- Add light variant here -->
		<!-- Add bg colour if image is not defined; bg will not show if image tag is present -->
		<v-card flat class="scl-card-cta-enhanced__card--light scl-card-cta-enhanced__card--orange scl-card-cta-enhanced__card--gold scl-card-cta-enhanced__card--ochre scl-card-cta-enhanced__card--blue scl-card-cta-enhanced__card--green">
			<div class="container">
				<!-- Add content alignment; Left align by default -->
				<div class="scl-card-cta-enhanced__content scl-card-cta-enhanced__content--left-align scl-card-cta-enhanced__content--right-align scl-card-cta-enhanced__content--center-align">
					<div class="scl-card-cta-enhanced__text-content">
						<h3 class="scl-card-cta-enhanced__title">
							Tell us what you need
						</h3>
						<h4 class="scl-card-cta-enhanced__subtitle">
							We'll help find what's right for you
						</h4>
						<p class="scl-card-cta-enhanced__text">
							13 30 32
						</p>
					</div>

					<scl-button class="scl-component scl-button" :api="primaryBtnApi" inline-template>
						<v-btn depressed class="scl-component scl-button scl-button--filled scl-button--no-fill scl-card-cta-enhanced__cta"
									:href="propApi.href">
							Get in touch
						</v-btn>
					</scl-button>
				</div>
			</div>
			
			<!-- Delete if source not defined -->
			<v-img src="https://cdn.vuetifyjs.com/images/cards/docks.jpg" class="scl-card-cta-enhanced__image">
			</v-img>
			<v-img src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg" class="scl-card-cta-enhanced__image scl-card-cta-enhanced__image--desktop">
			</v-img>
		</v-card>

	</div>
`;

// export template
export default template;
