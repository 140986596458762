/**
 * SclEnquireModal
 */

// template
const template = `
	<div>
		<div class="text-xs-center">
			<v-dialog
				v-model="dialog"
				:max-width="propApi.width"
				:persistent="propApi.persistent"
				content-class="scl-component scl-enquiry-modal"
			>
				<template v-slot:activator="{ on }">
					<v-btn
						color="accent"
						class="scl-component scl-button"
						:class="{ 'scl-button--small' : btnSmall }"
						depressed
						:outline="!btnSmall"
						@click="gtmAddToCart()"
						v-on="on"
					>
						Enquire Now
					</v-btn>
				</template>
				<v-card v-if="!dialog">
					<v-form class="scl-form" ref="form" v-model="valid">
						<v-icon class="scl-enquiry-modal__close" large @click="dialog = false">far fa-times-circle</v-icon>
						<v-container grid-list-xl class="scl-contact-form__container">
							<v-layout row>
								<v-flex lg12>
									<legend class="scl-form__title">MAKE AN ENQUIRY</legend>
								</v-flex>
							</v-layout>

							<v-layout row wrap>
								<v-flex :class="{ 'order-xs1' : $vuetify.breakpoint.smAndDown }" xs12 md7>
									<v-text-field
										class="scl-form__input"
										outline
										v-model="enquiry.firstName"
										:rules="[
											rules.required,
											rules.counter50,
											rules.lettersOnly
										]"
										label="First Name"
									></v-text-field>
									<v-text-field
										class="scl-form__input"
										outline
										v-model="enquiry.lastName"
										:rules="[
											rules.required,
											rules.counter50,
											rules.lettersOnly
										]"
										label="Last Name"
									></v-text-field>
									<v-text-field
										class="scl-form__input"
										outline
										v-model="enquiry.email"
										:rules="[rules.required, rules.email]"
										label="Email Address"
									></v-text-field>
									<v-text-field
										class="scl-form__input"
										outline
										v-model="enquiry.phone"
										:rules="[
											rules.required,
											rules.australianPhoneNumber
										]"
										label="Phone"
									></v-text-field>
									<v-text-field
										class="scl-form__input"
										outline
										v-model="enquiry.company"
										label="Company (Optional)"
									></v-text-field>
									<v-autocomplete
										attach=".scl-form__input--location"
										v-model="location"
										:items="locationItems"
										:search-input.sync="search"
										background-color="white"
										color="primary"
										class="scl-form__input scl-form__input--location"
										hide-no-data
										height="52"
										item-text="Description"
										item-value="API"
										placeholder="enter postcode or suburb"
										return-object
										clearable
										auto-select-first
										:loading="isLoading"
										cache-items
									>
									</v-autocomplete>
									<v-checkbox
										v-model="enquiry.reserve"
										class="scl-form__input scl-form__checkbox"
										label="I WOULD LIKE TO RESERVE THIS EQUIPMENT"
									></v-checkbox>

									<template v-if="enquiry.reserve">
										<v-layout row wrap>
											<v-flex xs12 sm6 class="py-0">
												<v-menu
													ref="dateFrom"
													v-model="dateFrom"
													:close-on-content-click="false"
													:nudge-right="40"
													lazy
													transition="scale-transition"
													offset-y
													full-width
													max-width="290px"
													min-width="290px"
												>
													<template v-slot:activator="scope">
														<label class="scl-form__label">from</label>
														<v-text-field
															v-model="dateFromFormatted"
															class="scl-form__input"
															outline
															hint="dd/mm/yyyy"
															v-on="scope.on"
														></v-text-field>
													</template>
													<v-date-picker v-model="dateFromIso" no-title @input="dateFrom = false"></v-date-picker>
												</v-menu>
											</v-flex>
											<v-flex xs12 sm6 class="py-0">
												<v-menu
													ref="dateTo"
													v-model="dateTo"
													:close-on-content-click="false"
													:nudge-right="40"
													lazy
													transition="scale-transition"
													offset-y
													full-width
													max-width="290px"
													min-width="290px"
												>
													<template v-slot:activator="scope">
														<v-text-field
															v-model="dateToFormatted"
															class="scl-form__input"
															outline
															label="To"
															hint="dd/mm/yyyy"
															v-on="scope.on"
														></v-text-field>
													</template>
													<v-date-picker v-model="dateToIso" no-title @input="dateTo = false"></v-date-picker>
												</v-menu>
											</v-flex>
										</v-layout>
									</template>

									<v-textarea
										class="scl-form__input scl-form__textarea"
										outline
										name="input-7-1"
										label="Your enquiry"
										v-model="enquiry.enquiry"
										:rules="[
											rules.required,
											rules.counter500
										]"
										height="168"
									></v-textarea>
									<v-btn block class="scl-form__btn" color="accent" depressed @click="submitForm()">Submit Enquiry</v-btn>
									<p class="scl-form__terms">By submitting this enquiry you agree to Coates Hire's Communication <a href="#">Terms &amp; Conditions</a>.</p>
								</v-flex>

								<!-- right column image -->
								<v-flex xs12 md5>
									<div class="scl-enquiry-modal__product">
										<h6 class="scl-form__label">Enquiring for</h6>
										<div class="scl-enquiry-modal__product-container">
											<v-img class="scl-enquiry-modal__product-img" src="https://via.placeholder.com/85/85" width="85" height="85" aspect-ratio="1" />
											<div class="scl-enquiry-modal__product-name">Product Name - Type - Measurement</div>
										</div>
									</div>
								</v-flex>
							</v-layout>
						</v-container>
					</v-form>
				</v-card>
				<v-card v-if="enquirySubmitted">
					<v-icon class="scl-enquiry-modal__close" large @click="dialog = false">far fa-times-circle</v-icon>
					<v-container grid-list-xl class="scl-contact-form__container">
						<v-layout row>
							<v-flex lg12>
								<legend class="scl-form__title">Thanks for your enquiry</legend>
								<p>We will be in touch</p>
							</v-flex>
						</v-layout>
					</v-container>
				</v-card>
			</v-dialog>
		</div>
	</div>
`;

// export template
export default template;
