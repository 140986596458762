// component
const component = {
	name: 'SclBreadcrumbs',
	template: null,
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.divider ? typeof obj.divider === 'string' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					divider: 'far fa-caret-right'
				},
				this.api
			);
		}
	}
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT) {
	component.template = require(`./${component.name}-template`).default;
}
// css import
import(`./_${component.name}.scss`);

export default component;
