import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps';
import GmapCluster from 'vue2-google-maps/dist/components/cluster';

Vue.use(VueGoogleMaps, {
	load: {
		// should use a different one for different environments via env configs
		// TEMP USING THE KEY FROM IOT
		key: process.env.VUE_APP_GOOGLE_PLACES_API_KEY,
		language: 'en-AU',
		// https://developers.google.com/maps/documentation/javascript/libraries
		libraries: 'geometry',
		region: 'AU'
	},
	installComponents: true
});

Vue.component('GmapCluster', GmapCluster);
