// component
const component = {
	name: 'SclWysiwyg',
	data() {
		return {};
	},
	computed: {
		setMargin() {
			if (this.$root.bannerHeight) {
				return '0px';
			} else {
				const margin = `${this.$root.headerHeight}px`;
				return margin;
			}
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT) {
	component.template = require(`./${component.name}-template`).default;
}

// css import
import(`./_${component.name}.scss`);

// export component
export default component;
