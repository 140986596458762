// css import
import('./_scl-social-share.scss');

// component
const component = {
	name: 'SclSocialShare',
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.type ? typeof obj.type === 'string' : true));
				return !set.has(false);
			}
		}
	},
	methods: {
		socialType(type) {
			switch (type) {
				case 'facebook':
					window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(document.URL)}&t=${encodeURIComponent(document.URL)}`);
					break;
				case 'linkedin':
					window.open(
						`http://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(document.URL)}&title=${encodeURIComponent(document.title)}`
					);
					break;
				case 'pinterest':
					window.open(`http://pinterest.com/pin/create/button/?url=${encodeURIComponent(document.URL)}&title=${encodeURIComponent(document.title)}`);
					break;
				case 'twitter':
					window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(document.title)} ${encodeURIComponent(document.URL)}`);
					break;
				case 'email':
					window.location.assign(`mailto:?subject=${encodeURIComponent(document.title)}&body=${encodeURIComponent(document.URL)}`);
					break;
				default:
					return;
			}
		},
		socialIcon(type) {
			switch (type) {
				case 'email':
					return 'fas fa-envelope-square';
				case 'facebook':
					return 'fab fa-facebook-f';
				case 'linkedin':
					return 'fab fa-linkedin-in';
				case 'pinterest':
					return 'fab fa-pinterest-square';
				case 'twitter':
					return 'fab fa-twitter';
				default:
					return '';
			}
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT) {
	component.template = require(`./${component.name}-template`).default;
}

export default component;
