// component
const component = {
	name: 'SclBranchAutocomplete',
	data() {
		return {
			entries: [],
			isLoading: false,
			model: null,
			search: null
		};
	},
	created() {
		this.fetchData();
	},
	computed: {
		items() {
			return this.entries.map(entry => {
				const Description = `${entry.SuburbName} ${entry.Postcode} ${entry.StateCode}`;
				return Object.assign({}, entry, { Description });
			});
		}
	},
	watch: {
		search() {
			return;
		}
	},
	methods: {
		clear() {
			this.model = null;
		},
		searchSelect() {
			if (this.model) {
				window.location = '/find-a-branch?lat=' + this.model.Latitude + '&lng=' + this.model.Longitude;
			} else {
				window.location = '/find-a-branch';
			}
		},
		fetchData() {
			if (this.entries.length > 0) {
				return;
			}
			if (this.isLoading) {
				return;
			}
			this.isLoading = true;
			// Lazily load input items
			window
				.fetch('/assets/data/suburbs.json')
				.then(res => res.json())
				.then(res => {
					this.entries = res;
				})
				.catch(err => {
					console.error(err);
				})
				.finally(() => (this.isLoading = false));
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT) {
	component.template = require(`./${component.name}-template`).default;
}

// css import
import(`./_${component.name}.scss`);

// export component
export default component;
