// component
const component = {
	name: 'SclExample',
	data() {
		return {
			jsMessage: 'example message from JS'
		};
	},
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.boolean ? typeof obj.boolean === 'boolean' : true));
				set.add(!!(obj.number ? typeof obj.number === 'number' : true));
				set.add(!!(obj.string ? typeof obj.string === 'string' : true));
				// set.add(!!(obj.arrayItem ? Array.isArray(obj.arrayItem) : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					boolean: false,
					number: 10,
					string: 'this is the default string'
				},
				this.api
			);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT) {
	component.template = require(`./${component.name}-template`).default;
}

// css import
import(`./_${component.name}.scss`);

// export component
export default component;
