// component
const component = {
	name: 'SclHeaderSmall',
	props: {},
	data() {
		return {
			mobileMenu: null,
			entries: [],
			model: null,
			search: null,
			isLoading: false
		};
	},
	mounted() {
		const originalOnKeyDown = this.$refs.autocompleteField.onKeyDown;
		this.$refs.autocompleteField.onKeyDown = e => (e.keyCode === 13 ? this.searchLink() : originalOnKeyDown(e));
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					search: {
						title: 'SEARCH',
						icon: 'fas fa-search'
					},
					contact: {
						number: '13 15 52',
						icon: 'fas fa-phone-alt'
					},
					navigationItemsIcon: 'fas fa-chevron-down',
					navigationSearchIcon: '',
					navigationItems: [
						{
							title: 'EQUIPMENT',
							subTitle: 'EQUIPMENT FOR HIRE',
							navigationSubItems: [
								{
									title: 'Access',
									url: '#'
								},
								{
									title: 'Air & Air Accessories',
									url: '#'
								}
							]
						},
						{
							title: 'SERVICES',
							subTitle: 'SERVICES',
							navigationSubItems: [
								{
									title: 'Temporary Works - Design & Installation',
									url: '#'
								},
								{
									title: 'Water Treatment',
									url: '#'
								}
							]
						},
						{
							title: 'TRAINING',
							subTitle: 'Training',
							navigationSubItems: [
								{
									title: 'Temporary Works - Design & Installation',
									url: '#'
								},
								{
									title: 'Water Treatment',
									url: '#'
								}
							]
						},
						{
							title: 'Search',
							subTitle: '',
							navigationSubItems: [],
							isSearch: true
						}
					]
				},
				this.api
			);
		},
		isAtTop() {
			return this.$root.isAtTop;
		},
		setMargin() {
			const margin = `${this.$root.headerHeight}px`;
			return margin;
		},
		setHeight() {
			const maxHeight = `calc(100% - ${this.$root.headerHeight}px)`;
			return maxHeight;
		},
		items() {
			return this.entries.map(entry => {
				const Name = `${entry.name}`;
				const Url = `${entry.url}`;
				const Keywords = `${entry.keywords}`;
				return Object.assign({}, entry, { Name, Url, Keywords });
			});
		}
	},
	watch: {
		search(val) {
			// Items have already been loaded
			if (this.items.length > 0) {
				return;
			}
			// Items have already been requested
			if (this.isLoading) {
				return;
			}
			if (val && val.length > 3) {
				this.isLoading = true;
				window
					.fetch(process.env.VUE_APP_PRODUCTSEARCH_API)
					.then(res => res.json())
					.then(res => {
						this.entries = res;
					})
					.catch(err => {
						console.error(err);
					})
					.finally(() => (this.isLoading = false));
			}
		},
		model(newModel) {
			window.location.href = newModel.Url;
		}
	},
	methods: {
		searchLink() {
			var searchurl = this.$attrs['data-searchurl'];
			if (this.search) {
				searchurl = searchurl + '?term=' + this.search.replace(/\s/g, '-');
			}
			window.location.href = searchurl;
		},
		customFilter(item, queryText) {
			const searchValues = (item.name + ' ' + item.keywords).toLowerCase();
			const searchTextValues = queryText.toLowerCase().split(' ');
			return searchTextValues.every(r => searchValues.includes(r));
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT) {
	component.template = require(`./${component.name}-template`).default;
}

// css import
import(`./_${component.name}.scss`);

export default component;
