/**
 * Sitecore Forms
 */

// template
const template = /* html */ `
	<div>
		<div class="scl-sc-forms">
			<v-container>
				<v-row>
					<v-col>
						<form>
							<fieldset>
								<legend><span>Lorem ipsum dolor</span></legend>
								<h2><span>Lorem ipsum dolor</span></h2>

								<label for="">Lorem ipsum dolor</label>
								<input class="scl-input-icon--user" required placeholder="placeholder text"></input>
								<span class="field-validation-valid">Lorem ipsum dolor</span>

								<label for="">Lorem ipsum dolor</label>
								<input class="scl-input-icon--email" required placeholder="placeholder text"></input>
								<span class="field-validation-valid">Lorem ipsum dolor</span>

								<label for="">Lorem ipsum dolor</label>
								<input class="scl-input-icon--phone" required placeholder="placeholder text"></input>
								<span class="field-validation-valid">Lorem ipsum dolor</span>

								<label for="">Lorem ipsum dolor</label>
								<input class="scl-input-icon--briefcase" required placeholder="placeholder text"></input>
								<span class="field-validation-valid">Lorem ipsum dolor</span>

								<label for="">Lorem ipsum dolor</label>
								<input class="scl-input-icon--location" required placeholder="placeholder text"></input>
								<span class="field-validation-valid">Lorem ipsum dolor</span>

								<label for="">Lorem ipsum dolor</label>
								<input class="scl-input-icon--rocket" required placeholder="placeholder text"></input>
								<span class="field-validation-valid">Lorem ipsum dolor</span>

								<label for="">Lorem ipsum dolor</label>
								<input class="input-validation-error" required />
								<span class="field-validation-error">Lorem ipsum dolor</span>

								<label for="">Lorem ipsum dolor</label>
								<select>
									<option>one</option>
									<option>two</option>
								</select>
								<span class="field-validation-valid">Lorem ipsum dolor</span>

								<label>
									<input type="checkbox" />
									Checkbox
								</label>
								<label>
									<input type="radio" name="radio" />
									Radio one
								</label>
								<label>
									<input type="radio" name="radio" />
									Radio two
								</label>
								<label for="">Lorem ipsum dolor<span>Optional</span></label>
								<textarea placeholder="Type your comments here"></textarea>
								<span class="field-validation-valid">Lorem ipsum dolor</span>
								<label for="">Lorem ipsum dolor</label>
								<input disabled />
								<span class="field-validation-valid">Lorem ipsum dolor</span>
								<label for="">Lorem ipsum dolor</label>
								<input required type="date" />
								<span class="field-validation-valid">Lorem ipsum dolor</span>
								<button type="submit">submit</button>
								<button type="submit" disabled>submit</button>
							</fieldset>
						</form>
					</v-col>
				</v-row>
			</v-container>
		</div>
		<div class="scl-sc-forms scl-sc-forms--dark">
			<v-container>
				<v-row>
					<v-col>
						<form>
							<fieldset>
								<legend><span>Lorem ipsum dolor</span></legend>
								<label for="">Lorem ipsum dolor</label>
								<input required />
								<span class="field-validation-valid">Lorem ipsum dolor</span>
								<label for="">Lorem ipsum dolor</label>
								<select>
									<option>one</option>
									<option>two</option>
								</select>
								<span class="field-validation-valid">Lorem ipsum dolor</span>
								<label for="">Lorem ipsum dolor<span>Optional</span></label>
								<textarea placeholder="Type your comments here"></textarea>
								<span class="field-validation-valid">Lorem ipsum dolor</span>
								<label for="">Lorem ipsum dolor</label>
								<input disabled />
								<span class="field-validation-valid">Lorem ipsum dolor</span>
								<button type="submit">submit</button>
							</fieldset>
						</form>
					</v-col>
				</v-row>
			</v-container>
		</div>
	</div>
`;

// export template
export default template;
