// imports
import VueUploadComponent from 'vue-upload-component';

// component
const component = {
	name: 'SclFileUpload',
	components: {
		FileUpload: VueUploadComponent
	},
	data() {
		return {
			files: [],
			uploadId: 123
		};
	},
	methods: {
		inputFile(newFile, oldFile) {
			if (oldFile == null || newFile.active !== oldFile.active) {
				// min size
				if (newFile.size > 10000 * 1024) {
					newFile = this.$refs.upload.update(newFile, { error: 'size' });
				} else if (oldFile != null && newFile.success !== oldFile.success) {
					this.$root.$emit('emitfileuploadevent', newFile.response);
				} else if (oldFile == null) {
					newFile = this.$refs.upload.update(newFile, { active: true });
				}
			}
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT) {
	component.template = require(`./${component.name}-template`).default;
}

// css import
import(`./_${component.name}.scss`);

// export component
export default component;
