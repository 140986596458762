/**
 * SclCardImage api
 *
 * href prop
 * @param {string} 'http://www.example.com'
 *
 * title prop
 * @param {string} 'Lorem ipsum dolor sit amet consectetur adipiscing'
 *
 * subtitle prop
 * @param {string} 'Lorem ipsum'
 *
 * textBottom prop
 * @param {boolean} false
 *
 * img prop
 * @param {string} 'https://picsum.photos/700/965'
 */

// template
const template = `
	<div>
		<!-- customer story variant -->
		<div v-if="propApi.isStoryVariant" class="scl-card-image__story-variant">
			<div class="scl-card-image__story-variant--bg-colour" :style="{ 'background-color': propApi.bgColour }">
				<v-img class="scl-card-image__story-variant--bg-image" :src="propApi.img">				
				<v-flex class="scl-card-image__story-variant--panel">
					<div class="scl-card-image__story-variant--panel-container">
						<h5 class="scl-card-image__story-variant--panel-title">{{ $root.dummyWord }}</h5>
						<p class="scl-card-image__story-variant--panel-description">{{ $root.dummyWords }}</p>
						<scl-button inline-template :api="secondaryBtnCta">
							<v-btn depressed  class="scl-component scl-button scl-button--outline scl-card-image__story-variant--btn"
								:href="propApi.href"
								:outline="propApi.outline"
								:target="propApi.target"
								:class="$vuetify.breakpoint.mdAndUp ? '' : 'scl-button--small'"
							>
								{{ propApi.copy }} <v-icon v-if="propApi.icon" class="scl-button__icon">{{ propApi.icon }}</v-icon>
							</v-btn>
						</scl-button>
					</div>
				</v-flex>
				</v-img>
			</div>
		</div>

		<!-- recursion card image -->
		<v-card v-else flat :href="propApi.href" :class="{'align-bottom' : propApi.textBottom}" class="scl-card-image__card">
			<v-img :src="propApi.img" class="scl-card-image__img" height="410">
				<div class="scl-card-image__img-overlay"></div>
				<div class="scl-card-image__title-container">
					<h4 class="scl-card-image__title">{{ propApi.title }}</h4>
					<h6 v-if="propApi.subtitle" class="scl-card-image__subtitle">{{ propApi.subtitle }}</h6>
				</div>
				<scl-button :api="{ href: 'http://www.example.com/', outline: true, copy: 'lorem ipsum' }" class="scl-component scl-button"></scl-button>
			</v-img>
		</v-card>
	</div>
`;

// export template
export default template;
