/**
 * SclBanner api
 *
 * bgImage prop
 * @param {string} "image2.png"
 *
 * hasBackground prop
 * @param {boolean} true || false
 */

// template
const template = `
	<div>
	<v-img :src="propApi.bgImage" :aspect-ratio="16/3">
		<v-container class="scl-banner__container">
			<v-layout row wrap align-center class="scl-banner__row">
				<v-flex xs12 md9 class="scl-banner__content">
					<h1 class="scl-banner__title"><scl-dummy-data :content="$root.dummyWords"></scl-dummy-data></h1>
					<h6 v-if="propApi.hasSubHeading" class="scl-banner__subheading">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</h6>
					<scl-button v-if="propApi.hasCta" :api="{ href: 'http://www.example.com/', outline: false, copy: 'lorem ipsum' }" class="scl-component scl-button"></scl-button>
				</v-flex>
			</v-layout>
		</v-container>
		</v-img>
	</div>
`;

// export template
export default template;
