import _find from 'lodash/find';

// component
const component = {
	name: 'SclSelect',
	data() {
		return {
			label: 'News Category'
		};
	},
	mounted() {
		this.checkURL();
	},
	methods: {
		checkURL() {
			const URL = window.location.pathname;
			const filtered = _find(this.$refs.filterSelect.items, o => {
				return o.value === URL;
			});
			if (filtered) {
				this.label = filtered.text;
			}
		},
		filter() {
			this.label = null;
			this.$nextTick(() => {
				const newUrl = this.$refs.filterSelect.selectedItems[0].value;
				window.location.href = newUrl;
			});
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT) {
	component.template = require(`./${component.name}-template`).default;
}

// css import
import(`./_${component.name}.scss`);

// export component
export default component;
