// component
const component = {
	name: 'SclEnquiryModal',
	data() {
		return {
			dialog: false,
			valid: true,
			entries: [],
			search: null,
			enquiry: {
				firstName: '',
				lastName: '',
				email: '',
				phone: '',
				company: '',
				location: '',
				postcode: '',
				state: '',
				storeName: '',
				reserve: false,
				hireDate: '',
				hireTo: '',
				subject: 'equipment',
				enquiry: '',
				pageUrl: window.location.href,
				productName: '',
				productCode: ''
			},
			enquirySubmitted: false,
			enquiryResult: null,
			dateFromIso: new Date().toISOString().substr(0, 10),
			dateToIso: new Date().toISOString().substr(0, 10),
			dateFromFormatted: this.formatDate(new Date().toISOString().substr(0, 10)),
			dateToFormatted: this.formatDate(new Date().toISOString().substr(0, 10)),
			dateFrom: false,
			dateTo: false,
			isLoading: false,
			location: null,
			rules: {
				required: value => !!value || 'Required',
				counter50: value => value.length <= 50 || 'Max 50 characters',
				counter500: value => value.length <= 500 || 'Max 500 characters',
				lettersOnly: value => {
					const lettersPattern = /^[a-zA-Z\s'-]+$/;
					return lettersPattern.test(value) || 'Name is invalid';
				},
				australianPhoneNumber: value => {
					const pattern = /^0([0-9]{9})$/;
					return pattern.test(value) || 'Phone number is invalid';
				},
				email: value => {
					const pattern =
						/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
					return pattern.test(value) || 'Please enter a valid email address';
				}
			}
		};
	},
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.width ? typeof obj.width === 'string' : true));
				set.add(!!(obj.productCode ? typeof obj.productCode === 'string' : true));
				set.add(!!(obj.productName ? typeof obj.productName === 'string' : true));
				set.add(!!(obj.productCategory ? typeof obj.productCategory === 'string' : true));
				set.add(!!(obj.productPrice ? typeof obj.productPrice === 'string' : true));
				set.add(!!(obj.persistent ? typeof obj.persistent === 'boolean' : true));
				set.add(!!(obj.enquiryType ? typeof obj.enquiryType === 'string' : true));
				return !set.has(false);
			}
		},
		btnSmall: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					width: '960px',
					persistent: false,
					productName: '',
					productCode: '',
					produceCategory: '',
					producePrice: '',
					enquiryType: 'equipment'
				},
				this.api
			);
		},
		locationItems() {
			return this.entries.map(entry => {
				const Description = `${entry.SuburbName} ${entry.Postcode} ${entry.StateCode}`;
				return Object.assign({}, entry, { Description });
			});
		}
	},
	watch: {
		dateFromIso() {
			this.dateFromFormatted = this.formatDate(this.dateFromIso);
		},
		dateToIso() {
			this.dateToFormatted = this.formatDate(this.dateToIso);
		},
		search(val) {
			// Items have already been loaded
			if (this.locationItems.length > 0) {
				return;
			}
			// Items have already been requested
			if (this.isLoading) {
				return;
			}
			// Lazily load input items
			if (val && val.length > 3) {
				this.isLoading = true;
				window
					.fetch('/assets/data/suburbs.json')
					.then(res => res.json())
					.then(res => {
						this.entries = res;
					})
					.catch(err => {
						console.error(err);
					})
					.finally(() => (this.isLoading = false));
			}
		},
		location(val) {
			if (val) {
				window
					.fetch(process.env.VUE_APP_CLOSESTBRANCH_API + '?latitude=' + val.Latitude + '&longitude=' + val.Longitude, {
						headers: {
							'Content-Type': 'application/json'
						}
					})
					.then(res => res.json())
					.then(res => {
						if (res.errorMessage) {
							console.error('closest branch lookup error: ' + res.errorMessage);
						}
						this.enquiry.storeName = res.storeCode;
					});
			}
		}
	},
	methods: {
		clear() {
			this.enquiry.location = null;
		},
		formatDate(date) {
			if (!date) {
				return null;
			}
			const [year, month, day] = date.split('-');
			return `${day}/${month}/${year}`;
		},
		parseDate(date) {
			if (!date) {
				return null;
			}
			const [month, day, year] = date.split('/');
			return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
		},
		submitForm() {
			if (!this.$refs.form.validate()) {
				return;
			}
			this.enquirySubmitted = true;
			this.gtmPurchase();
			var url = process.env.VUE_APP_CONTACTFORM_API;

			const contactuspageurl = new URL(window.location.href);
			const utm_source = contactuspageurl.searchParams.get('utm_source');
			const utm_medium = contactuspageurl.searchParams.get('utm_medium');
			const utm_campaign = contactuspageurl.searchParams.get('utm_campaign');
			const utm_content = contactuspageurl.searchParams.get('utm_content');
			const utm_term = contactuspageurl.searchParams.get('utm_term');

			let utmParams =
				(utm_source ? 'utm_source=' + encodeURIComponent(utm_source) : '') +
				(utm_medium ? '&utm_medium=' + encodeURIComponent(utm_medium) : '') +
				(utm_campaign ? '&utm_campaign=' + encodeURIComponent(utm_campaign) : '') +
				(utm_content ? '&utm_content=' + encodeURIComponent(utm_content) : '') +
				(utm_term ? '&utm_term=' + encodeURIComponent(utm_term) : '');
			if (utmParams.length > 0 && utmParams.charAt(0) === '&') {
				utmParams = utmParams.substring(1);
			}

			this.enquiry.productName = this.propApi.productName;
			this.enquiry.productCode = this.propApi.productCode;
			this.enquiry.hireDate = this.dateFromFormatted;
			this.enquiry.hireTo = this.dateToFormatted;
			this.enquiry.postcode = this.location.Postcode;
			this.enquiry.state = this.location.StateCode;
			this.enquiry.location = this.location.Description;
			this.enquiry.subject = this.propApi.enquiryType;
			this.enquiry.utm = utmParams;
			fetch(url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(this.enquiry)
			}).then(response => {
				var data = response.json();
				if (data) {
					this.enquiryResult = data;
				}
			});
		},
		gtmAddToCart: function () {
			var productData = {
				name: this.propApi.productName,
				id: this.propApi.productCode,
				brand: 'Coates Hire',
				price: this.propApi.productPrice,
				quantity: '1',
				category: this.propApi.productCategory
			};
			var dataObjectAddToCart = {
				event: 'addToCart',
				ecommerce: {
					add: {
						products: [productData]
					}
				},
				eventCallback: function () {
					var dataObjectCheckout = {
						event: 'checkout',
						ecommerce: {
							checkout: {
								actionField: { step: 1 },
								products: [productData]
							}
						}
					};
					window.dataLayer.push(dataObjectCheckout);
				},
				eventTimeout: 2000
			};
			if (typeof window.dataLayer != 'undefined') {
				window.dataLayer.push(dataObjectAddToCart);
			}
		},
		gtmPurchase: function () {
			var productData = {
				name: this.propApi.productName,
				id: this.propApi.productCode,
				brand: 'Coates Hire',
				category: this.propApi.productCategory,
				price: this.propApi.productPrice,
				quantity: '1'
			};
			var dataObjectPurchase = {
				event: 'purchase',
				ecommerce: {
					purchase: {
						actionField: {
							id: 'T_' + Math.random().toString(36).substr(2, 9),
							affiliation: 'Online Store',
							revenue: this.propApi.productPrice
						},
						products: [productData]
					}
				}
			};
			if (typeof window.dataLayer != 'undefined') {
				window.dataLayer.push(dataObjectPurchase);
			}
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT) {
	component.template = require(`./${component.name}-template`).default;
}

// css import
import(`./_${component.name}.scss`);

// export component
export default component;
