/**
 * SclBannerSmall api
 *
 * bgImage prop
 * @param {string} "image2.png"
 *
 * hasBackground prop
 * @param {boolean} true || false
 */

// template
const template = `
	<div v-bind:style="[propApi.hasBackground ? { 'background-image': 'url('+propApi.bgImage+')' } : {}]">
		<v-container class="scl-banner-small__container py-0">
			<v-layout row wrap align-end class="scl-banner-small__row">
				<v-flex xs12 md8>
					<h3 v-if="propApi.hasCaptionHeader" class="scl-banner-small__caption">
						<scl-dummy-data :content="$root.dummyWords"></scl-dummy-data>
					</h3>
					<h2 class="scl-banner-small__title"><scl-dummy-data :content="$root.dummyWords"></scl-dummy-data></h2>
					<scl-button v-if="propApi.hasCta" :api="{ href: 'http://www.example.com/', outline: true, copy: 'lorem ipsum' }" class="scl-component scl-button"></scl-button>
				</v-flex>
			</v-layout>
		</v-container>
	</div>
`;

// export template
export default template;
