/**
 * SclCardImageSelector api
 *
 * href prop
 * @param {string} 'http://www.example.com'
 *
 * title prop
 * @param {string} 'Lorem ipsum dolor sit amet consectetur adipiscing'
 *
 * subtitle prop
 * @param {string} 'Lorem ipsum'
 *
 * textBottom prop
 * @param {boolean} false
 *
 * img prop
 * @param {string} 'https://picsum.photos/700/965'
 */

// template
const template = `
	<div>
		<v-container grid-list-xl>
			<v-layout row wrap>
				<v-flex sm12>
					<v-card flat class="scl-card-image-selector__card">
						<v-img :src="propApi.img" class="scl-card-image-selector__img">
							<div class="scl-card-image-selector__img-overlay"></div>

							<div class="scl-card-image-selector__image-tag">
								<p class="scl-card-image-selector__image-tag-name">{{ propApi.tag }}</p>
							</div>

							<div class="scl-card-image-selector__tertiary-cta">
								<scl-button class="scl-component" :api="tertiaryBtnApi" inline-template>
									<v-btn depressed class="subheading scl-component scl-button scl-button scl-button--tertiary"
										:color="propApi.color"
										:href="propApi.href"
										:outline="propApi.outline"
										:block="propApi.block"
										:target="propApi.target"
										:class="{'scl-button--outline' : propApi.outline }">
										{{ propApi.copy }} <v-icon v-if="propApi.icon" class="scl-button__icon">{{ propApi.icon }}</v-icon>
										<div class="scl-button__tally" v-if="propApi.tally">
											<p class='mb-0'>{{propApi.tally}}</p>
										</div>
									</v-btn>
								</scl-button>
								<v-icon class="scl-card-image-selector__tertiary-cta-icon">fas fa-arrow-right</v-icon>
							</div>

							<div class="scl-card-image-selector__title-container">
								<h3 class="scl-card-image-selector__title">{{ propApi.title }}</h3>
								<p v-if="propApi.subtitle" class="scl-card-image-selector__subtitle">{{ propApi.subtitle }}</p>
							</div>

							<div class="scl-card-image-selector__dropdown-container">
								<v-select
									v-model="selectedLink"
									:items="propApi.dropDownSelector"
									item-text="title"
									item-value="url"
									append-icon="fas fa-chevron-down"
									class="scl-card-image-selector__selection"
									attach="scl-card-image-selector__content"
									single-line
									@change="selectChange"
								></v-select>

								<scl-button class="scl-component scl-button" :api="primaryBtnApi" inline-template>
									<v-btn depressed class="scl-component scl-button scl-card-image-selector__primary-cta"
										   :color="propApi.color"
										   :href="propApi.href"
										   :outline="propApi.outline"
										   :block="propApi.block"
										   :target="propApi.target"
										   :class="{'scl-button--outline' : propApi.outline }">
										{{ propApi.copy }} <v-icon v-if="propApi.icon" class="scl-button__icon">{{ propApi.icon }}</v-icon>
										<div class="scl-button__tally" v-if="propApi.tally">
											<p class='mb-0'>{{propApi.tally}}</p>
										</div>
									</v-btn>
								</scl-button>
							</div>

						</v-img>
					</v-card>
				</v-flex>
			</v-layout>
		</v-container>
	</div>
`;

// export template
export default template;
