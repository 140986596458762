/**
 * application entry
 */

// imports
import 'babel-polyfill';
import 'whatwg-fetch';
import 'intersection-observer';
import Vue from 'vue';
//import './registerServiceWorker'; //conflicts with Sitecore authentication, breaks same-page signin
import './plugins/vuetify';
import './plugins/googlemaps';
import '@fortawesome/fontawesome-pro/css/fontawesome.css';
import '@fortawesome/fontawesome-pro/css/regular.css';
import '@fortawesome/fontawesome-pro/css/solid.css';
import '@fortawesome/fontawesome-pro/css/brands.css';
import '@fortawesome/fontawesome-pro/css/light.css';
import _debounce from 'lodash/debounce';
import _kebabCase from 'lodash/kebabCase';
import SitecoreExpEditorPlugin from './plugins/experienceEditor';
import '@/_scl-corporate/assets/css/app.scss';

// components
import GlobalComponents from './components/GlobalComponents';

// mixins
import DummyData from './mixins/global/DummyData';

// add all components globally
GlobalComponents.forEach(component => {
	Vue.component(_kebabCase(component.name), component);
});

Vue.config.devtools = true;

Vue.use(SitecoreExpEditorPlugin);

// instantiate Vue
window.vueApp = new Vue({
	el: '#app',
	mixins: [DummyData],
	data() {
		return {
			isAtTop: true,
			tabCorporate: 0,
			headerHeight: 0,
			bannerHeight: null,
			windowWidth: window.innerWidth,
			windowHeight: window.innerHeight
		};
	},
	created() {
		window.addEventListener('scroll', this.handleScroll);
	},
	mounted: function () {
		this.$nextTick(() => {
			if (this.$refs.bannerRef) {
				this.$refs['headerRef'].$el.classList.add('scl-top-nav--gradient');
			}
			window.addEventListener('resize', this.onResize);
		});
		setTimeout(() => {
			document.body.style.opacity = 1;
			this.setHeight();
		}, 500);
		setTimeout(() => {
			this.setBannerHeight();
		}, 1000);
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.onResize);
	},
	methods: {
		handleScroll: _debounce(function () {
			this.isAtTop = false;
			if (this.$refs.headerRef.$refs.autocompleteField.isFocused !== null) {
				this.$refs.headerRef.$refs.autocompleteField.isFocused = false;
			}
			if (window.scrollY === 0) {
				this.isAtTop = true;
			}
		}, 200),
		setHeight() {
			this.$nextTick(() => {
				if (this.$refs.headerRef) {
					this.headerHeight = this.$refs.headerRef.$refs.header.clientHeight;
				}
			});
		},
		setBannerHeight() {
			this.$nextTick(() => {
				if (this.$refs.bannerRef) {
					this.bannerHeight = 1;
				} else {
					this.bannerHeight = 0;
				}
			});
		},
		onResize() {
			this.windowWidth = window.innerWidth;
			this.windowHeight = window.innerHeight;
			this.setHeight();
		}
	}
});
