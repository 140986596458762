/**
 * SclGallery api
 */

// template
const template = `
	<div>
		<div class="scl-gallery-product__container" id="gallery-product">
			<v-img @click="imgEvent($event)" class="scl-gallery-product__img-small" data-img="https://picsum.photos/id/100/503" src="https://picsum.photos/id/100/503" aspect-ratio="1"></v-img>
			<v-img @click="imgEvent($event)" class="scl-gallery-product__img-small" data-img="https://picsum.photos/id/101/503" src="https://picsum.photos/id/101/503" aspect-ratio="1"></v-img>
			<v-img @click="imgEvent($event)" class="scl-gallery-product__img-small" data-img="https://picsum.photos/id/102/503" src="https://picsum.photos/id/102/503" aspect-ratio="1"></v-img>
		</div>
		<v-img class="scl-gallery-product__img-large" :src="selectedImg" aspect-ratio="1">
		</v-img>
	</div>
`;

// export template
export default template;
