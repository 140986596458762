// css import
import('./_scl-spacer.scss');

// component
const component = {
	name: 'SclSpacer',
	props: {
		api: {
			type: Object,
			required: false
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT) {
	component.template = require(`./${component.name}-template`).default;
}

export default component;
