// component
const component = {
	name: 'SclHeaderLarge',
	props: {},
	data() {
		return {
			isSearch: true,
			entries: [],
			model: null,
			search: null,
			isLoading: false,
			isSearchOpen: false,
			menuItemActive: new Array(10).fill(false)
		};
	},
	mounted() {},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					navigationItemsIcon: 'fas fa-chevron-down',
					navigationSearchIcon: '',
					navigationItems: [
						{
							title: 'Equipment Hire',
							subTitle: 'Equipment for hire',
							navigationSubItems: [
								{
									title: 'Access',
									url: '#'
								},
								{
									title: 'Earthmoving',
									url: '#'
								},
								{
									title: 'Lighting',
									url: '#'
								},
								{
									title: 'Site Accomodation',
									url: '#'
								},
								{
									title: 'Air & Air Accessories',
									url: '#'
								},
								{
									title: 'Floor & Cleaning',
									url: '#'
								},
								{
									title: 'Materials Handling',
									url: '#'
								},
								{
									title: 'Tools & Equipment',
									url: '#'
								},
								{
									title: 'Concrete & Masonry',
									url: '#'
								},
								{
									title: 'Generators & Power Distribution',
									url: '#'
								},
								{
									title: 'Pipeline Testing',
									url: '#'
								},
								{
									title: 'Traffic Management',
									url: '#'
								},
								{
									title: 'Confined Space Entry',
									url: '#'
								},
								{
									title: 'Ladders & Scaffolding',
									url: '#'
								},
								{
									title: 'Propping & Structural Support',
									url: '#'
								},
								{
									title: 'Trucks Vehicles & Trailers',
									url: '#'
								},
								{
									title: 'Compaction',
									url: '#'
								},
								{
									title: 'Landscaping',
									url: '#'
								},
								{
									title: 'Water Management & Pumps',
									url: '#'
								},
								{
									title: 'Welding',
									url: '#'
								},
								{
									title: 'Construction Lasers',
									url: '#'
								},
								{
									title: 'Lift & Shift',
									url: '#'
								},
								{
									title: 'Shoring',
									url: '#'
								},
								{
									title: 'Find out more',
									css: ['scl-header-large__menu-item-cta'],
									url: '#'
								}
							]
						},
						{
							title: 'Engineering Solutions',
							subTitle: 'SERVICES',
							navigationSubItems: [
								{
									title: 'Temporary Works - Design & Installation',
									url: '#'
								},
								{
									title: 'Water Treatment',
									url: '#'
								}
							]
						},
						{
							title: 'Industrial  Solutions',
							subTitle: 'Training',
							navigationSubItems: [
								{
									title: 'Temporary Works - Design & Installation',
									url: '#'
								},
								{
									title: 'Water Treatment',
									url: '#'
								}
							]
						},
						{
							title: 'Training',
							subTitle: 'Training',
							navigationSubItems: [
								{
									title: 'Temporary Works - Design & Installation',
									url: '#'
								},
								{
									title: 'Water Treatment',
									url: '#'
								}
							]
						},
						{
							title: 'Technology',
							subTitle: 'Training',
							navigationSubItems: [
								{
									title: 'Temporary Works - Design & Installation',
									url: '#'
								},
								{
									title: 'Water Treatment',
									url: '#'
								}
							]
						}
					]
				},
				this.api
			);
		},
		isAtTop() {
			return this.$root.isAtTop;
		},
		getHeight() {
			return this.$root.headerHeight;
		},
		items() {
			return this.entries.map(entry => {
				const Name = `${entry.name}`;
				const Url = `${entry.url}`;
				const Keywords = `${entry.keywords}`;
				return Object.assign({}, entry, { Name, Url, Keywords });
			});
		}
	},
	watch: {
		search(val) {
			// Items have already been loaded
			if (this.items.length > 0) {
				return;
			}
			// Items have already been requested
			if (this.isLoading) {
				return;
			}
			if (val && val.length > 3) {
				this.isLoading = true;
				window
					.fetch(process.env.VUE_APP_PRODUCTSEARCH_API)
					.then(res => res.json())
					.then(res => {
						this.entries = res;
					})
					.catch(err => {
						console.error(err);
					})
					.finally(() => (this.isLoading = false));
			}
		},
		model(newModel) {
			window.location.href = newModel.Url;
		},
		isSearchOpen: {
			handler(newVal) {
				if (newVal) {
					const autocompleteInput = this.$refs.autocompleteField.$refs.input;
					setTimeout(() => {
						autocompleteInput.focus();
					}, 500);
				}
			}
		}
	},
	methods: {
		searchLink() {
			var searchurl = this.$attrs['data-searchurl'];
			if (this.search) {
				searchurl = searchurl + '?term=' + this.search.replace(/\s/g, '-');
			}
			window.location.href = searchurl;
		},
		customFilter(item, queryText) {
			const searchValues = (item.name + ' ' + item.keywords).toLowerCase();
			const searchTextValues = queryText.toLowerCase().split(' ');
			return searchTextValues.every(r => searchValues.includes(r));
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT) {
	component.template = require(`./${component.name}-template`).default;
}

// css import
import(`./_${component.name}.scss`);

export default component;
