// component
const component = {
	name: 'SclBannerBranch',
	data() {
		return {
			entries: [],
			isLoading: false,
			locationBtnDisabled: false,
			locationText: 'Use my location',
			model: null,
			search: null
		};
	},
	created() {
		this.fetchData();
	},
	computed: {
		items() {
			return this.entries.map(entry => {
				const Description = `${entry.SuburbName} ${entry.Postcode} ${entry.StateCode}`;
				return Object.assign({}, entry, { Description });
			});
		}
	},
	watch: {
		model(val) {
			if (this.model) {
				this.$root.$emit('mapFilterEvent', {
					mapCenter: {
						lat: val.Latitude,
						lng: val.Longitude
					}
				});
			}
		},
		search() {}
	},
	methods: {
		clear() {
			this.model = null;
		},
		getLocation() {
			const me = this;
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(success, error);
				this.locationBtnDisabled = true;
				this.locationText = 'Please wait';
			} else {
				this.locationBtnDisabled = true;
				this.locationText = 'Location not supported';
			}
			// success
			function success(position) {
				const lat = position.coords.latitude;
				const lng = position.coords.longitude;
				me.$root.$emit('mapFilterEvent', {
					mapCenter: {
						lat: lat,
						lng: lng
					}
				});
				me.locationBtnDisabled = false;
				me.locationText = 'Use my location';
				me.clear();
			}
			// error
			function error(err) {
				me.locationBtnDisabled = false;
				me.locationText = 'Please try again';
				console.error(err);
			}
		},
		fetchData() {
			if (this.entries.length > 0) {
				return;
			}
			if (this.isLoading) {
				return;
			}
			this.isLoading = true;
			// Lazily load input items
			window
				.fetch('/assets/data/suburbs.json')
				.then(res => res.json())
				.then(res => {
					this.entries = res;
				})
				.catch(err => {
					console.error(err);
				})
				.finally(() => (this.isLoading = false));
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT) {
	component.template = require(`./${component.name}-template`).default;
}

// css import
import(`./_${component.name}.scss`);

// export component
export default component;
