/**
 * SclTabCarousel api
 *
 * @param {boolean} centered - centers the tabs
 * @param {boolean} disableTouch - disables swiping of tab content
 * @param {boolean} grow - stretch the tabs to take up all horizontal space
 * @param {boolean} hideSlider - hides the slider underneath tabs
 * @param {boolean} icons - will display icons above the tabs
 * @param {boolean} overflowArrows - shows arrow when tabs dont fit within its parent container
 * @param {boolean} right - aligns tabs to the right
 */

// template
const template = `
    <div>
        <v-carousel :height="getHeight ? carouselHeight : '100%'" :dark="false" class="scl-tab-carousel__carousel" :hide-controls="true" :hide-delimiters="$vuetify.breakpoint.smAndUp" :cycle="false" v-model="activeCarousel" v-bind:style="{'margin-top' : $root.windowWidth >= 1024? setMargin : '0'}">
            <!-- desktop large carousel -->
            <v-carousel-item src="https://picsum.photos/1920/1080">
                <v-container fill-height>
                    <v-layout>
                        <v-flex xs12 sm10 lg8 align-self-center class="scl-tab-carousel__content">
                            <h1 class="scl-tab-carousel__title">Hire and so much more</h1>
                            <div class="scl-tab-carousel__caption"> <p class="scl-ww">{{ $root.dummyParagraph }}</p></div>
                            <v-btn :ripple="false" class="scl-tab-carousel__btn " color="accent" depressed href="#" large>Learn more</v-btn>
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-carousel-item>
            <v-carousel-item src="https://picsum.photos/1920/1080">
            <v-container fill-height>
                <v-layout>
                    <v-flex xs12 sm10 lg8 align-self-center class="scl-tab-carousel__content">
                        <h1 class="scl-tab-carousel__title">Hire and so much more</h1>
                        <h5 class="scl-tab-carousel__caption">{{ $root.dummyParagraph }}</h5>
                        <v-btn :ripple="false" class="scl-tab-carousel__btn " color="accent" depressed href="#" large>Learn more</v-btn>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-carousel-item>
        <v-carousel-item src="https://picsum.photos/1920/1080">
        <v-container fill-height>
            <v-layout>
                <v-flex xs12 sm10 lg8 align-self-center class="scl-tab-carousel__content">
                    <h1 class="scl-tab-carousel__title">Hire and so much more</h1>
                    <h5 class="scl-tab-carousel__caption">{{ $root.dummyParagraph }}</h5>
                    <v-btn :ripple="false" class="scl-tab-carousel__btn " color="accent" depressed href="#" large>Learn more</v-btn>
                </v-flex>
            </v-layout>
        </v-container>
    </v-carousel-item>
    <v-carousel-item src="https://picsum.photos/1920/1080">
    <v-container fill-height>
        <v-layout>
            <v-flex xs12 sm10 lg8 align-self-center class="scl-tab-carousel__content">
                <h1 class="scl-tab-carousel__title">Hire and so much more</h1>
                <h5 class="scl-tab-carousel__caption">{{ $root.dummyParagraph }}</h5>
                <v-btn :ripple="false" class="scl-tab-carousel__btn " color="accent" depressed href="#" large>Learn more</v-btn>
            </v-flex>
        </v-layout>
    </v-container>
</v-carousel-item>
        </v-carousel>
    <v-container class="scl-tab-content__tab-container" >
        <v-tabs
            v-model="activeTab"
            :centered="propApi.centered"
            :grow="propApi.grow"
            :hide-slider="propApi.hideSlider"
            :icons-and-text="propApi.icons"
            :right="propApi.right"
            :show-arrows="propApi.overflowArrows"
            class="scl-tab-carousel__tabs-header"
        >
            <v-tab href="#tab-0" class="scl-tab-carousel__tab-header-item" :ripple="false">
                <v-icon class="scl-tab-carousel__tab-icon">far fa-phone</v-icon>
                <div class="scl-tab-carousel__tab-tile">
                    {{ $root.dummyWord }}
                </div>
            </v-tab>
            <v-tab href="#tab-1" class="scl-tab-carousel__tab-header-item" :ripple="false">
                <v-icon class="scl-tab-carousel__tab-icon">far fa-phone</v-icon>
                <div class="scl-tab-carousel__tab-tile">
                    {{ $root.dummyWords }}
                </div>
            </v-tab>
            <v-tab href="#tab-2" class="scl-tab-carousel__tab-header-item" :ripple="false">
            <v-icon class="scl-tab-carousel__tab-icon">far fa-phone</v-icon>
            <div class="scl-tab-carousel__tab-tile">
                {{ $root.dummyWords }}
            </div>
            </v-tab>
            <v-tab href="#tab-3" class="scl-tab-carousel__tab-header-item" :ripple="false">
                <v-icon class="scl-tab-carousel__tab-icon">far fa-phone</v-icon>
                <div class="scl-tab-carousel__tab-tile">
                    {{ $root.dummyWords }}
                </div>
            </v-tab>
        </v-tabs>

        <v-tabs-items v-model="activeTab" :touchless="propApi.disableTouch" class="scl-tab-carousel__tab-items">
            <v-tab-item value="tab-0" class="scl-tab-carousel__tab-item"><p>{{ $root.dummyParagraph }}</p></v-tab-item>
            <v-tab-item value="tab-1" class="scl-tab-carousel__tab-item">{{ $root.dummyWords }}</v-tab-item>
            <v-tab-item value="tab-2" class="scl-tab-carousel__tab-item">{{ $root.dummyParagraph }}</v-tab-item>
            <v-tab-item value="tab-3" class="scl-tab-carousel__tab-item">{{ $root.dummyParagraph }}</v-tab-item>
        </v-tabs-items>
        </v-container>
    </div>
	
`;

// export template
export default template;
