/**
 * Scl1Col
 */

// template
const template = `
	<scl-1-Col inline-template class="scl-component scl-1-col">
		<v-container grid-list-lg>
			<v-layout row wrap>
				<v-flex xs12>
					<scl-dummy-data :content="$root.dummyParagraph"></scl-dummy-data>
				</v-flex>
			</v-layout>
		</v-container>
	</scl-1-Col>
`;

// export template
export default template;
