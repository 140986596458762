// component
const component = {
	name: 'SclCardIcon',
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.hasLink ? typeof obj.hasLink === 'boolean' : true));
				set.add(!!(obj.sideIcon ? typeof obj.sideIcon === 'boolean' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					hasLink: false,
					sideIcon: false,
					copy: this.$root.dummyParagraph
				},
				this.api
			);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT) {
	component.template = require(`./${component.name}-template`).default;
}

// css import
import(`./_${component.name}.scss`);

// export component
export default component;
