// component
const component = {
	name: 'SclBranchMap',
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.latitude ? typeof obj.latitude === 'number' : true));
				set.add(!!(obj.longitude ? typeof obj.longitude === 'number' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					latitude: -33.908,
					longitude: 151.202
				},
				this.api
			);
		},
		latlng() {
			return Object.assign({
				lat: this.propApi.latitude,
				lng: this.propApi.longitude
			});
		}
	},
	template: null
};

component.template = require(`./${component.name}-template`).default;

export default component;
