import _groupBy from 'lodash/groupBy';

// component
const component = {
	name: 'SclBranchFind',
	data() {
		return {
			branches: [],
			hasErrored: false,
			loadingBranches: false,
			ACT: {},
			NSW: {},
			NT: {},
			QLD: {},
			SA: {},
			TAS: {},
			VIC: {},
			WA: {},
			panel: [null, null, null, null, null, null, null, null]
		};
	},
	created() {
		this.fetchData();
	},
	methods: {
		panelUpdate(index) {
			const updatedPanel = [null, null, null, null, null, null, null, null];
			updatedPanel[index] = 0;
			this.panel = updatedPanel;
		},
		getBranchTotal(state) {
			let size = 0;
			Object.entries(state).forEach(region => {
				size = size += region[1].length;
			});
			return size;
		},
		sortBranches(arr) {
			// sort all by state
			const sortState = _groupBy(arr, branch => {
				return branch.StoreState;
			});
			// sorting by region constructor
			function sortRegion(arr) {
				const region = _groupBy(arr, branch => {
					return branch.StoreRegion;
				});
				return region;
			}
			// sort all by region
			this.ACT = new sortRegion(sortState.ACT);
			this.NSW = new sortRegion(sortState.NSW);
			this.NT = new sortRegion(sortState.NT);
			this.QLD = new sortRegion(sortState.QLD);
			this.SA = new sortRegion(sortState.SA);
			this.TAS = new sortRegion(sortState.TAS);
			this.VIC = new sortRegion(sortState.VIC);
			this.WA = new sortRegion(sortState.WA);
		},
		fetchData() {
			// branches have already been loaded
			if (this.branches.length > 0) {
				return;
			}
			// branches have already been requested
			if (this.loadingBranches) {
				return;
			}
			this.loadingBranches = true;
			// get branches
			window
				.fetch(process.env.VUE_APP_STORES_API)
				.then(res => res.json())
				.then(res => {
					this.branches = res.Data;
					this.sortBranches(res.Data);
				})
				.catch(err => {
					this.hasErrored = true;
					console.error(err);
				})
				.finally(() => (this.loadingBranches = false));
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT) {
	component.template = require(`./${component.name}-template`).default;
}

// css import
import(`./_${component.name}.scss`);

export default component;
