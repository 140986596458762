// component
const component = {
	name: 'SclSubscribeModal',
	props: {
		id: {
			type: String
		}
	},
	template: null,
	data: () => {
		return {
			dialog: true
		};
	},
	mounted() {
		this.handleCookieStatus();
	},
	computed: {
		isDismissed() {
			console.log('isDismissed:' + this.dialog);
			return this.dialog;
		}
	},
	methods: {
		handleCookieStatus() {
			const cookieName = this.id;
			const cookies = document.cookie.split(';');
			for (var i = 0; i < cookies.length; i++) {
				let item = cookies[i];
				while (item.charAt(0) == ' ') {
					item = item.substring(1, item.length);
				}
				if (item.indexOf(cookieName) == 0) {
					this.dialog = false;
				}
			}
		},
		dismiss() {
			this.dialog = false;
			const now = new Date();
			const minutes = 30;
			const cookievalue = this.id;
			now.setTime(now.getTime() + minutes * 60 * 1000);
			document.cookie = `${cookievalue}=${cookievalue}; expires=${now}; path=/`;
			this.$root.setHeight();
		}
	}
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT) {
	component.template = require(`./${component.name}-template`).default;
}

// css import
import(`./_${component.name}.scss`);

// export component
export default component;
