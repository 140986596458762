// component
const component = {
	name: 'SclCardImageSelector',
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.img ? typeof obj.img === 'string' : true));
				set.add(!!(obj.tag ? typeof obj.tag === 'string' : true));
				set.add(!!(obj.title ? typeof obj.title === 'string' : true));
				set.add(!!(obj.subtitle ? typeof obj.subtitle === 'string' : true));
				set.add(!!(obj.dropDownSelector ? typeof obj.dropDownSelector === 'object' : true));
				set.add(!!(obj.href ? typeof obj.href === 'string' : true));
				return !set.has(false);
			}
		}
	},
	data() {
		return {
			selectedLink: null
		};
	},
	mounted() {
		this.selectedLink = this.propApi.dropDownSelector[0].url;
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					img: '',
					tag: '',
					href: '',
					title: '',
					tertiaryCopy: '',
					subtitle: '',
					dropDownSelector: []
				},
				this.api
			);
		},
		tertiaryBtnApi() {
			return {
				copy: this.propApi.tertiaryCopy,
				outline: false,
				href: this.propApi.href
			};
		},
		primaryBtnApi() {
			return {
				copy: 'Let’s go',
				outline: false,
				href: this.selectedLink,
				icon: 'fas fa-arrow-right'
			};
		}
	},
	methods: {
		selectChange(e) {
			this.selectedLink = e;
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT) {
	component.template = require(`./${component.name}-template`).default;
}

// css import
import(`./_${component.name}.scss`);

// export component
export default component;
