/**
 * SclCarouselMedia api
 *
 * bgImages prop
 * @param {Array} Array "['image.png', 'image2.png']"
 *
 * cycle prop
 * @param {boolean} true || false
 *
 * hideDelimiter prop
 * @param {boolean} true || false
 *
 * hideControls prop
 * @param {boolean} true || false
 *
 * interval prop
 * @param {number} int 5000
 *
 * nextIcon prop
 * @param {string} string "fas fa-chevron-circle-right"
 *
 * prevIcon prop
 * @param {string} string "fas fa-chevron-circle-right"
 *
 * delimiterIcon prop
 * @param {string} string "fas fa-skull"
 *
 * color prop: accepts rgb or named
 * @param {string} string #00b9dd
 * @param {string} string red
 */

// template
const template = `
		<v-carousel
			:cycle="propApi.cycle"
			:delimiter-icon="propApi.delimiterIcon"
			:hide-controls="propApi.hideControls"
			:hide-delimiters="propApi.hideDelimiters"
			:height="imgHeight"
			:interval="propApi.interval"
			:next-icon="propApi.nextIcon"
			:prev-icon="propApi.prevIcon"
			class="scl-banner--search__carousel"
		>
			<v-carousel-item :lazy="true" v-for="bgImage in propApi.bgImages" :key="bgImage" :src="bgImage" class="scl-banner--search__carousel-item">
				<v-container fill-height>
					<v-layout>
						<v-flex xs12 sm10 md8 lg6 align-self-center class="scl-banner--search__content">
							<h1 class="scl-banner__title">EQUIPMENT HIRE AND SERVICES</h1>
							<h2 class="scl-banner__caption">SEARCH EQUIPMENT</h2>
							<v-autocomplete
								v-model="model"
								:items="items"
								:search-input.sync="search"
								:filter="customFilter"
								class="scl-banner--search__autocomplete scl-input scl-autocomplete"
								hide-no-data
								height="52"
								item-text="Description"
								item-value="API"
								prepend-inner-icon="far fa-search"
								background-color="hsla(0,0%,100%,.5)"
								placeholder="We can make it happen"
								return-object
								ref="bannerSearchAutoComplete"
								auto-select-first
								:loading="isLoading"
								cache-items
							>
								<template v-if="$vuetify.breakpoint.smAndUp" v-slot:append>
									<v-btn v-if="!model" class="scl-banner--search__search-btn" color="accent" depressed href="#">GO</v-btn>
									<v-btn v-else @click="clear" class="scl-banner-autocomplete__clear-btn" flat icon>
										<v-icon color="primary">far fa-times-circle</v-icon>
									</v-btn>
								</template>
							</v-autocomplete>
							<v-btn v-if="$vuetify.breakpoint.xsOnly" block class="scl-banner--search__search-btn ma-0" color="accent" depressed href="#" large>GO</v-btn>
						</v-flex>
					</v-layout>
				</v-container>
			</v-carousel-item>
		</v-carousel>
`;

// export template
export default template;
