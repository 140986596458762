/**
 * SclWysiwyg
 */

// template
const template = `
	<scl-wysiwyg inline-template class="scl-component scl-ww" :style="{'margin-top': ($vuetify.breakpoint.mdAndUp? setMargin : '0')}">
		<div>
			<h1><scl-dummy-data :content="$root.dummyWords"></scl-dummy-data></h1>
			<h2><scl-dummy-data :content="$root.dummyWords"></scl-dummy-data></h2>
			<h3><scl-dummy-data :content="$root.dummyWords"></scl-dummy-data></h3>
			<h4><scl-dummy-data :content="$root.dummyWords"></scl-dummy-data></h4>
			<h5><scl-dummy-data :content="$root.dummyWords"></scl-dummy-data></h5>
			<h6><scl-dummy-data :content="$root.dummyWords"></scl-dummy-data></h6>
			<p><scl-dummy-data :content="$root.dummyParagraph"></scl-dummy-data></p>
			<ul>
				<li><scl-dummy-data :content="$root.dummyWords"></scl-dummy-data></li>
				<li><scl-dummy-data :content="$root.dummyWords"></scl-dummy-data></li>
				<li><scl-dummy-data :content="$root.dummyWords"></scl-dummy-data></li>
				<li><scl-dummy-data :content="$root.dummyWords"></scl-dummy-data></li>
				<li><scl-dummy-data :content="$root.dummyWords"></scl-dummy-data></li>
			</ul>
			<ul>
				<li><scl-dummy-data :content="$root.dummyWords"></scl-dummy-data></li>
				<li><scl-dummy-data :content="$root.dummyWords"></scl-dummy-data></li>
				<li><scl-dummy-data :content="$root.dummyWords"></scl-dummy-data></li>
				<li><scl-dummy-data :content="$root.dummyWords"></scl-dummy-data></li>
				<li><scl-dummy-data :content="$root.dummyWords"></scl-dummy-data></li>
			</ul>
			<div class="highlight">
				<p><scl-dummy-data :content="$root.dummyParagraph"></scl-dummy-data></p>
			</div>
			<div class="img-right">
				<scl-dummy-data :content="$root.dummyImageSquare" image></scl-dummy-data>
			</div>
			<p><scl-dummy-data :content="$root.dummyParagraph"></scl-dummy-data></p>
			<p><scl-dummy-data :content="$root.dummyParagraph"></scl-dummy-data></p>
			<p><scl-dummy-data :content="$root.dummyParagraph"></scl-dummy-data></p>
			<div class="img-left">
				<scl-dummy-data :content="$root.dummyImageSquare" image></scl-dummy-data>
			</div>
			<p><scl-dummy-data :content="$root.dummyParagraph"></scl-dummy-data></p>
			<p><scl-dummy-data :content="$root.dummyParagraph"></scl-dummy-data></p>
			<p><scl-dummy-data :content="$root.dummyParagraph"></scl-dummy-data></p>
		</div>
	</scl-wysiwyg>
`;

// export template
export default template;
