/**
 * SclIconCardGrid
 *
 * background prop
 * @param {boolean} false
 *
 * titleCenter prop
 * @param {boolean} false
 *
 * cta prop
 * @param {boolean} true
 */

// template
const template = /* html */ `
	<div>
		<v-container v-if="$vuetify.breakpoint.smAndUp" grid-list-xl class="scl-icon-card-grid__container">
			<v-layout row wrap class="scl-icon-card-grid__row">
				<v-flex xs12 md6>
					<v-layout>
						<v-flex xs3><scl-card-icon></scl-card-icon></v-flex>
						<v-flex xs3><scl-card-icon></scl-card-icon></v-flex>
						<v-flex xs3><scl-card-icon></scl-card-icon></v-flex>
						<v-flex xs3><scl-card-icon></scl-card-icon></v-flex>
					</v-layout>
				</v-flex>
				<v-flex xs12 md6>
					<v-layout>
						<v-flex xs3><scl-card-icon></scl-card-icon></v-flex>
						<v-flex xs3><scl-card-icon></scl-card-icon></v-flex>
						<v-flex xs3><scl-card-icon></scl-card-icon></v-flex>
						<v-flex xs3><scl-card-icon></scl-card-icon></v-flex>
					</v-layout>
				</v-flex>
			</v-layout>
		</v-container>
		<v-carousel v-else :hide-delimiters="false" interval="7000" :cycle="false">
			<!-- card icon -->
			<v-carousel-item>
				<v-container>
					<v-layout>
						<v-flex xs12>
							<scl-card-icon></scl-card-icon>
						</v-flex>
					</v-layout>
				</v-container>
			</v-carousel-item>

			<!-- card icon -->
			<v-carousel-item>
				<v-container>
					<v-layout>
						<v-flex xs12>
						<scl-card-icon></scl-card-icon>
						</v-flex>
					</v-layout>
				</v-container>
			</v-carousel-item>

			<!-- card icon -->
			<v-carousel-item>
				<v-container>
					<v-layout>
						<v-flex xs12>
						<scl-card-icon></scl-card-icon>
						</v-flex>
					</v-layout>
				</v-container>
			</v-carousel-item>

			<!-- card icon -->
			<v-carousel-item>
				<v-container>
					<v-layout>
						<v-flex xs12>
						<scl-card-icon></scl-card-icon>
						</v-flex>
					</v-layout>
				</v-container>
			</v-carousel-item>

			<!-- card icon -->
			<v-carousel-item>
				<v-container>
					<v-layout>
						<v-flex xs12>
						<scl-card-icon></scl-card-icon>
						</v-flex>
					</v-layout>
				</v-container>
			</v-carousel-item>

			<!-- card icon -->
			<v-carousel-item>
				<v-container>
					<v-layout>
						<v-flex xs12>
						<scl-card-icon></scl-card-icon>
						</v-flex>
					</v-layout>
				</v-container>
			</v-carousel-item>

			<!-- card icon -->
			<v-carousel-item>
				<v-container>
					<v-layout>
						<v-flex xs12>
						<scl-card-icon></scl-card-icon>
						</v-flex>
					</v-layout>
				</v-container>
			</v-carousel-item>

			<!-- card icon -->
			<v-carousel-item>
				<v-container>
					<v-layout>
						<v-flex xs12>
						<scl-card-icon></scl-card-icon>
						</v-flex>
					</v-layout>
				</v-container>
			</v-carousel-item>
		</v-carousel>
	</div>
`;

// export template
export default template;
