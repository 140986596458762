import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify, {
	breakpoint: {
		thresholds: {
			xs: 600,
			sm: 960,
			md: 1280,
			lg: 1920,
			xl: 1921
		}
	},
	iconfont: 'fa',
	theme: {
		accent: '#FF6105',
		info: '#FF6105',
		primary: '#54565A',
		secondary: '#000000',
		// below have not changed in brand refresh
		error: '#cc4b37',
		success: '#3adb76',
		warning: '#ffae00'
	}
});
