import axios from 'axios';

// component
const component = {
	name: 'SclSubscribeForm',
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.variant ? typeof obj.variant === 'string' : true));
				return !set.has(false);
			}
		}
	},
	data() {
		return {
			valid: false,
			emailAddress: '',
			emailRules: [v => !!v || 'E-mail is required', v => /.+@.+/.test(v) || 'E-mail must be valid'],
			firstName: '',
			firstNameRules: [v => !!v || 'First Name is required'],
			lastName: '',
			success: false,
			error: false,
			honeypot: ''
		};
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					variant: null
				},
				this.api
			);
		}
	},
	methods: {
		subscribe() {
			this.$refs.subscribeForm.validate();
			var url = process.env.VUE_APP_NEWSLETTERSUBSCRIBE_API;
			if (this.valid && this.honeypot === '') {
				const payload = {
					email: this.emailAddress,
					firstName: this.firstName,
					lastName: this.lastName
				};
				axios.post(url, payload).then(response => {
					this.success = response.data.success;
					this.error = !response.data.success;
				});
			}
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT) {
	component.template = require(`./${component.name}-template`).default;
}

// css import
import(`./_${component.name}.scss`);

// export component
export default component;
