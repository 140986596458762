/**
 * SclCarouselCustomerStory api
 *
 * cardNewsItems prop includes an array of object including
 * img prop
 * @param {string} "image2.png"
 *
 * title prop
 * @param {string} "title"
 *
 * accent prop
 * @param {string} "#ffa069"
 *
 * copy prop
 * @param {string} "Long text description"
 *
 * newsLink prop
 * @param {string} "www.google.com"
 *
 */

// template
const template = `
	<div class="scl-card-news">

		<v-card flat class="scl-card-news__card" :href="$root.dummyUrl">
			<v-img
				:src="$root.dummyImage43" class="scl-card-news__image"
				:aspect-ratio="$vuetify.breakpoint.smAndUp? '1.33': '1.75'"
			>
			</v-img>

			<div class="scl-card-news__content">
				<h5 class="scl-card-news__title">{{ $root.dummyWord }}</h5>
				<p class="scl-card-news__description">{{ $root.dummyParagraph }}</p>
			</div>

			<scl-button inline-template :api="primaryBtnApi">
					<v-btn depressed class="scl-component scl-button scl-button--small scl-button--tertiary scl-card-news__link"
						:outline="propApi.outline"
						:class="{'scl-button--outline' : propApi.outline}">
						{{ propApi.copy }} <v-icon class="scl-button__icon">{{ propApi.icon }}</v-icon>
					</v-btn>
				</scl-button>
			<div class="scl-card-news__card-hover"></div>
		</v-card>

	</div>
`;

// export template
export default template;
