<template>
	<div class="scl-pagination-carousel">
		<v-carousel :class="[carouselclass, 'scl-carousel-card']" v-bind="$props" v-model="currentIndex">
			<v-carousel-item v-for="(pairs, i) in pariedItems" :key="i">
				<div v-for="(item, j) in pairs" :key="j">
					<slot></slot>
					<a :href="item.url" class="scl-card-news__card v-card v-card--flat v-sheet theme--light">
						<div class="v-responsive v-image scl-card-news__image" style="height: 372px">
							<div class="v-responsive__sizer" style="padding-bottom: 100%"></div>
							<div class="v-image__image v-image__image--cover" :style="{ backgroundImage: 'url(' + item.image.src + ')' }"></div>
							<div class="v-responsive__content"></div>
						</div>
						<div class="scl-card-news__content">
							<h5 class="scl-card-news__title">{{ item.title }}</h5>
						</div>
						<div
							:href="item.href"
							class="scl-button scl-button--small scl-button--tertiary scl-card-news__link v-btn v-btn--depressed theme--light"
						>
							<div class="v-btn__content">
								View All
								<i aria-hidden="true" class="v-icon scl-button__icon fal fa-arrow-right theme--light"></i>
							</div>
						</div>
					</a>
				</div>
			</v-carousel-item>
		</v-carousel>
		<div class="scl-pagination-carousel__controls">{{ currentIndex + 1 }} of {{ pariedItems.length }}</div>
	</div>
</template>

<script>
export default {
	name: 'SclCarouselCardPaginated',
	props: {
		cycle: {
			type: Boolean
		},
		hideControls: {
			type: Boolean
		},
		hideDelimiters: {
			type: Boolean
		},
		height: {
			type: String
		},
		interval: {
			type: Number
		},
		items: {
			type: Array,
			default: () => [
				{
					description:
						"Coates Hire's push-pull props such as acrow props and tilt props are ideal for high load applications. Explore structural support and propping hire today.",
					displayName: null,
					id: 'ce185e9b-0a6c-4ef0-9776-276dc86e42cf',
					image: {
						alt: 'Propping and structural support',
						border: '',
						class: '',
						hSpace: 0,
						height: 500,
						language: { Name: 'en' },
						mediaExists: true,
						mediaId: 'f2b1dee3-b866-4d98-a682-77b5ba63692a',
						src: '/-/media/images/equipment/propping-and-structural-support_500x500.jpg',
						title: '',
						vSpace: 0,
						width: 500
					},
					intro_text: '',
					itemId: '00000000-0000-0000-0000-000000000000',
					keywords: null,
					name: 'Propping Structural Support equipment',
					pageContent:
						'<p>Advances in precast concrete construction techniques have brought new challenges as panel sizes and resulting loads have escalated. Our range of push-pull props suit high load applications. Props are individually tagged with service certificates. Available in 4 sizes.</p>\n',
					shortDescription: '',
					title: 'Propping & Structural Support',
					url: '/propping-structural-support-equipment'
				}
			]
		},
		light: {
			type: Boolean,
			default: true
		},
		carouselclass: {
			type: String
		}
	},
	data() {
		return {
			currentIndex: 0
		};
	},
	computed: {
		pairCount() {
			const { md, sm, xs } = this.$vuetify.breakpoint;
			if (xs) {
				return 1;
			}
			if (sm) {
				return 2;
			}
			if (md) {
				return 3;
			}
			return 4;
		},
		pariedItems() {
			return this.items.reduce((result, value, index, array) => {
				if (index % this.pairCount === 0) {
					result.push(array.slice(index, index + this.pairCount));
				}
				return result;
			}, []);
		}
	},
	mounted() {
		console.warn(this);
	}
};
</script>

<style lang="scss">
.scl-pagination-carousel {
	position: relative;

	.v-responsive__content {
		display: grid;
		grid-column-gap: 16px;
		grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
		justify-content: center;
	}

	.v-carousel {
		box-shadow: none;
		padding: 0 0 64px;

		&__next,
		&__prev {
			@media (max-width: $sm) {
				transform: translateY(calc(-100% - 8px));
				top: 100%;
				z-index: 2;
			}
		}

		&__controls {
			background: transparent;

			.v-btn--active {
				color: $color-orange;

				.v-icon {
					opacity: 1;
				}
			}

			@media (max-width: $sm) {
				display: none;
			}
		}
	}

	&__controls {
		bottom: 0;
		height: 32px;
		position: absolute;
		text-align: center;
		width: 100%;

		@media (min-width: $sm) {
			display: none;
		}
	}
}
</style>
