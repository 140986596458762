/**
 * All components for global registration
 */

import { SclAlert } from './alert/index';
import { SclBanner, SclBannerBranch, SclBannerSearch, SclBannerSmall, SclBannerDescriptor, SclBannerPage } from './banner/index';
import * as CarouselComponents from './carousel/index';
import { SclCarouselTestimonial } from './carousel-testimonial/index';
import { SclEnquiryModal } from './modal/index';
import { SclExample } from './example/index';
import { SclDummyData } from './dummy/index';
import { SclBreadcrumbs } from './breadcrumbs/index';
import { SclButton } from './button/index';
import { SclCardCta, SclCardCtaEnhanced, SclCardImage, SclCardSolid, SclCardIcon, SclCard, SclCardLinkSelector, SclCardImageSelector } from './card/index';
import { SclFooter, SclFooterListLarge } from './footer/index';
import { SclGalleryProduct } from './gallery/index';
import { Scl1Col, Scl2Col, Scl2Col4, Scl2Col8, Scl3Col, Scl4Col, SclGridList } from './grid/index';
import { SclMap } from './map/index';
import {
	SclBranchAutocomplete,
	SclContactForm,
	SclEnquirePanel,
	SclFileUpload,
	SclSelect,
	SclSubscribeForm,
	SclSubscribePanel,
	SclSubscribeModal,
	SclScForms
} from './form/index';
import { SclHeaderLarge, SclHeaderSmall } from './header/index';
import { SclPagination } from './pagination/index';
import { SclWysiwyg } from './wysiwyg/index';
import { SclProductSummary, SclProductDetail, SclProductSubtype } from './product/index';
import { SclBranchDetail, SclBranchFind, SclBranchMap } from './branch/index';
import { SclSocialShare } from './social-share/index';
import { SclSpacer } from './spacer/index';
import { SclCardNews, SclCardImageText, SclIconCardGrid } from './theme/coates/index';
import { SclTabs, SclTabCarousel, SclTabContent } from './tabs/index';

export default [
	// NOTE: this might be a cleaner way to register components instead
	// of double import/exporting them
	...Object.values(CarouselComponents),
	Scl1Col,
	Scl2Col,
	Scl2Col4,
	Scl2Col8,
	Scl3Col,
	Scl4Col,
	SclAlert,
	SclBranchAutocomplete,
	SclBanner,
	SclBannerBranch,
	SclBannerDescriptor,
	SclBannerPage,
	SclBannerSearch,
	SclBannerSmall,
	SclBranchDetail,
	SclBranchFind,
	SclBranchMap,
	SclBreadcrumbs,
	SclButton,
	SclCard,
	SclCardCta,
	SclCardCtaEnhanced,
	SclCardImage,
	SclCardIcon,
	SclCardNews,
	SclCardImageText,
	SclCardSolid,
	SclCardImageSelector,
	SclCardLinkSelector,
	SclContactForm,
	SclDummyData,
	SclEnquiryModal,
	SclEnquirePanel,
	SclExample,
	SclFileUpload,
	SclFooter,
	SclFooterListLarge,
	SclScForms,
	SclGalleryProduct,
	SclGridList,
	SclHeaderLarge,
	SclHeaderSmall,
	SclIconCardGrid,
	SclMap,
	SclPagination,
	SclProductDetail,
	SclProductSubtype,
	SclProductSummary,
	SclSelect,
	SclSocialShare,
	SclSpacer,
	SclSubscribeForm,
	SclSubscribePanel,
	SclSubscribeModal,
	SclTabs,
	SclTabCarousel,
	SclTabContent,
	SclCardImageSelector,
	SclBannerDescriptor,
	SclCarouselTestimonial,
	SclWysiwyg
];
