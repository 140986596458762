// component
const component = {
	name: 'SclGalleryProduct',
	data() {
		return {
			selectedImg: ''
		};
	},
	mounted() {
		this.setImg();
	},
	methods: {
		imgEvent(event) {
			this.setImg(event);
		},
		setImg(event) {
			if (event) {
				this.selectedImg = event.target.parentElement.dataset.img;
			} else {
				this.selectedImg = document.querySelector('#gallery-product div:first-child').dataset.img;
			}
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT) {
	component.template = require(`./${component.name}-template`).default;
}

// css import
import(`./_${component.name}.scss`);

// export component
export default component;
