/**
 * SclBreadcrumb api
 *
 * string prop
 * @param {string} 'fas fa-chevron-right'
 */

const template = `
	<div>
		<v-breadcrumbs>
			<template v-slot:divider>
				<v-icon>
					{{ propApi.divider }}
				</v-icon>
			</template>
			<v-breadcrumbs-item href="/" class="scl-breadcrumbs-item">
				<scl-dummy-data :content="$root.dummyWord" />
			</v-breadcrumbs-item>
			<v-breadcrumbs-item href="/"  class="scl-breadcrumbs-item-active">
				<scl-dummy-data :content="$root.dummyWords" />
			</v-breadcrumbs-item>
		</v-breadcrumbs>
	</div>
`;

export default template;
