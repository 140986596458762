/**
 * SclTabContent api
 *
 * @param {boolean} centered - centers the tabs
 * @param {boolean} disableTouch - disables swiping of tab content
 * @param {boolean} grow - stretch the tabs to take up all horizontal space
 * @param {boolean} hideSlider - hides the slider underneath tabs
 * @param {boolean} icons - will display icons above the tabs
 * @param {boolean} overflowArrows - shows arrow when tabs dont fit within its parent container
 * @param {boolean} right - aligns tabs to the right
 */

// template
const template = `
    <div>
        <v-container v-if="$vuetify.breakpoint.mdAndUp" class="scl-tab-content__tab-container" >
            <v-tabs
                v-model="activeTab"
                :centered="propApi.centered"
                :grow="propApi.grow"
                :hide-slider="propApi.hideSlider"
                :icons-and-text="propApi.icons"
                :right="propApi.right"
                :show-arrows="propApi.overflowArrows"
                class="scl-tab-content__tabs-header"
            >
                <v-tab href="#tab-0" class="scl-tab-content__tab-header-item" :ripple="false">
                    <v-icon class="scl-tab-content__tab-icon">icon-Ventilation-air-quality_PHVAC</v-icon>
                    <div class="scl-tab-content__tab-tile">
                        {{ $root.dummyWord }}
                    </div>
                </v-tab>
                <v-tab href="#tab-1" class="scl-tab-content__tab-header-item" :ripple="false">
                    <v-icon class="scl-tab-content__tab-icon">icon-Service-Support_PHVAC</v-icon>
                    <div class="scl-tab-content__tab-tile">
                        {{ $root.dummyWords }}
                    </div>
                </v-tab>
                <v-tab href="#tab-2" class="scl-tab-content__tab-header-item" :ripple="false">
                <v-icon class="scl-tab-content__tab-icon">icon-Industrial-air_PHVAC</v-icon>
                <div class="scl-tab-content__tab-tile">
                    {{ $root.dummyWords }}
                </div>
                </v-tab>
                <v-tab href="#tab-3" class="scl-tab-content__tab-header-item" :ripple="false">
                    <v-icon class="scl-tab-content__tab-icon">icon-Fluid-chilling_PHVAC</v-icon>
                    <div class="scl-tab-content__tab-tile">
                        {{ $root.dummyWords }}
                    </div>
                </v-tab>
            </v-tabs>

            <v-tabs-items v-model="activeTab" :touchless="propApi.disableTouch" class="scl-tab-content__tab-items">
                <v-tab-item value="tab-0" class="scl-tab-content__tab-item">
                    <v-layout row wrap class="scl-grid-list__row scl-tab-content__ww">
                        <v-flex xs12 md8>
                            <h4>Project Management</h4>
                            <iframe id="youtube-player" width="320" height="315" src="https://www.youtube.com/embed/eSra8akEZkQ?enablejsapi=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                            {{ $root.dummyParagraph }}
                        </v-flex>
                        <v-flex xs12 md4>
                            <h5>Related resources</h5>
                            <ol>
                                <li>
                                    <a href="#">
                                        Messa commodo, sed gavida vitae pharetra
                                        <v-icon>fal fa-file-pdf</v-icon>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        Tempus vitae, nulla rhoncus
                                        <v-icon>fal fa-arrow-right</v-icon>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        Varius varius nullam etiam sed scelerisque
                                        <v-icon>fal fa-external-link</v-icon>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        Tempus vitae, nulla rhoncus
                                        <v-icon>fal fa-file-signature</v-icon>
                                    </a>
                                </li>
                            </ol>
                        </v-flex>
                    </v-layout>
                    <v-layout row wrap class="scl-grid-list__row scl-tab-content__nav">
                        <v-flex xs6 class="">
                            <v-btn class="scl-tab-content__nav-btn scl-tab-content__nav-btn--left" color="accent" outline depressed @click="prev">
                                <v-icon class="scl-button__icon">
                                    fal fa-arrow-left
                                </v-icon> 
                                Previous
                            </v-btn>
                        </v-flex>
                        <v-flex xs6 class="">
                            <v-btn class="scl-tab-content__nav-btn scl-tab-content__nav-btn--right" color="accent" outline depressed @click="next">
                                Next
                                <v-icon class="scl-button__icon">
                                    fal fa-arrow-right
                                </v-icon> 
                            </v-btn>
                        </v-flex>
                    </v-layout>
                </v-tab-item>

                <v-tab-item value="tab-1" class="scl-tab-content__tab-item">
                    <v-layout row wrap class="scl-grid-list__row scl-tab-content__ww">
                        <v-flex xs12 md8 class="scl-tab-content">
                            <h4>Project Management</h4>
                            <div>
                                {{ $root.dummyParagraph }}
                            </div>
                            <iframe id="youtube-player-test" width="320" height="315" src="https://www.youtube.com/embed/eSra8akEZkQ?enablejsapi=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                           
                        </v-flex>
                        <v-flex xs12 md4>
                            <h5>Related resources</h5>
                            <ol>
                                <li>
                                    <a href="#">
                                        Messa commodo, sed gavida vitae pharetra
                                        <v-icon>fal fa-file-pdf</v-icon>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        Tempus vitae, nulla rhoncus
                                        <v-icon>fal fa-arrow-right</v-icon>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        Varius varius nullam etiam sed scelerisque
                                        <v-icon>fal fa-external-link</v-icon>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        Tempus vitae, nulla rhoncus
                                        <v-icon>fal fa-file-signature</v-icon>
                                    </a>
                                </li>
                            </ol>
                        </v-flex>
                    </v-layout>
                    <v-layout row wrap class="scl-grid-list__row scl-tab-content__nav">
                        <v-flex xs6 class="">
                            <v-btn class="scl-tab-content__nav-btn scl-tab-content__nav-btn--left" color="accent" outline depressed @click="prev">
                                <v-icon class="scl-button__icon">
                                    fal fa-arrow-left
                                </v-icon> 
                                Previous
                            </v-btn>
                        </v-flex>
                        <v-flex xs6 class="">
                            <v-btn class="scl-tab-content__nav-btn scl-tab-content__nav-btn--right" color="accent" outline depressed @click="next">
                                Next
                                <v-icon class="scl-button__icon">
                                    fal fa-arrow-right
                                </v-icon> 
                            </v-btn>
                        </v-flex>
                    </v-layout>
                </v-tab-item>

                <v-tab-item value="tab-2" class="scl-tab-content__tab-item">
                    <v-layout row wrap class="scl-grid-list__row scl-tab-content__ww">
                        <v-flex xs12 md8>
                            <h4>Project Management</h4>
                            {{ $root.dummyParagraph }}
                        </v-flex>
                        <v-flex xs12 md4>
                            <h5>Related resources</h5>
                            <ol>
                                <li>
                                    <a href="#">
                                        Messa commodo, sed gavida vitae pharetra
                                        <v-icon>fal fa-file-pdf</v-icon>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        Tempus vitae, nulla rhoncus
                                        <v-icon>fal fa-arrow-right</v-icon>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        Varius varius nullam etiam sed scelerisque
                                        <v-icon>fal fa-external-link</v-icon>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        Tempus vitae, nulla rhoncus
                                        <v-icon>fal fa-file-signature</v-icon>
                                    </a>
                                </li>
                            </ol>
                        </v-flex>
                    </v-layout>
                    <v-layout row wrap class="scl-grid-list__row scl-tab-content__nav">
                        <v-flex xs6 class="">
                            <v-btn class="scl-tab-content__nav-btn scl-tab-content__nav-btn--left" color="accent" outline depressed @click="prev">
                                <v-icon class="scl-button__icon">
                                    fal fa-arrow-left
                                </v-icon> 
                                Previous
                            </v-btn>
                        </v-flex>
                        <v-flex xs6 class="">
                            <v-btn class="scl-tab-content__nav-btn scl-tab-content__nav-btn--right" color="accent" outline depressed @click="next">
                                Next
                                <v-icon class="scl-button__icon">
                                    fal fa-arrow-right
                                </v-icon> 
                            </v-btn>
                        </v-flex>
                    </v-layout>
                </v-tab-item>
                <v-tab-item value="tab-3" class="scl-tab-content__tab-item">
                    <v-layout row wrap class="scl-grid-list__row scl-tab-content__ww">
                        <v-flex xs12 md8>
                            <h4>Project Management</h4>
                            {{ $root.dummyParagraph }}
                        </v-flex>
                        <v-flex xs12 md4>
                            <h5>Related resources</h5>
                            <ol>
                                <li>
                                    <a href="#">
                                        Messa commodo, sed gavida vitae pharetra
                                        <v-icon>fal fa-file-pdf</v-icon>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        Tempus vitae, nulla rhoncus
                                        <v-icon>fal fa-arrow-right</v-icon>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        Varius varius nullam etiam sed scelerisque
                                        <v-icon>fal fa-external-link</v-icon>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        Tempus vitae, nulla rhoncus
                                        <v-icon>fal fa-file-signature</v-icon>
                                    </a>
                                </li>
                            </ol>
                        </v-flex>
                    </v-layout>
                    <v-layout row wrap class="scl-grid-list__row scl-tab-content__nav">
                        <v-flex xs6 class="">
                            <v-btn class="scl-tab-content__nav-btn scl-tab-content__nav-btn--left" color="accent" outline depressed @click="prev">
                                <v-icon class="scl-button__icon">
                                    fal fa-arrow-left
                                </v-icon> 
                                Previous
                            </v-btn>
                        </v-flex>
                        <v-flex xs6 class="">
                            <v-btn class="scl-tab-content__nav-btn scl-tab-content__nav-btn--right" color="accent" outline depressed @click="next">
                                Next
                                <v-icon class="scl-button__icon">
                                    fal fa-arrow-right
                                </v-icon> 
                            </v-btn>
                        </v-flex>
                    </v-layout>
                </v-tab-item>
            </v-tabs-items>
           
        </v-container>
        <v-container v-else class="scl-tab-content__tab-container" >
            <v-expansion-panel v-model="panel">
                <v-expansion-panel-content :expand-icon="panel == 0 ? 'fal fa-minus' : 'fal fa-plus'">
                    <template v-slot:header >
                        <div class="scl-tab-content__accordion-title">Item 1</div>
                    </template>
                    <v-card>
                        <v-card-text>
                            <v-flex xs12>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </v-flex>
                            <v-flex xs12>
                                <h5>Related resources</h5>
                                <ol>
                                    <li>
                                        <a href="#">
                                            Messa commodo, sed gavida vitae pharetra
                                            <v-icon>fal fa-file-pdf</v-icon>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            Tempus vitae, nulla rhoncus
                                            <v-icon>fal fa-arrow-right</v-icon>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            Varius varius nullam etiam sed scelerisque
                                            <v-icon>fal fa-external-link</v-icon>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            Tempus vitae, nulla rhoncus
                                            <v-icon>fal fa-file-signature</v-icon>
                                        </a>
                                    </li>
                                </ol>
                            </v-flex>
                        </v-card-text>
                    </v-card>
                </v-expansion-panel-content>
                <v-expansion-panel-content :expand-icon="panel == 1 ? 'fal fa-minus' : 'fal fa-plus'">
                    <template v-slot:header v>
                        <div class="scl-tab-content__accordion-title">Item 2</div>
                    </template>
                    <v-card>
                        <v-card-text>
                            <v-flex xs12>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </v-flex>
                            <v-flex xs12>
                                <h5>Related resources</h5>
                                <ol>
                                    <li>
                                        <a href="#">
                                            Messa commodo, sed gavida vitae pharetra
                                            <v-icon>fal fa-file-pdf</v-icon>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            Tempus vitae, nulla rhoncus
                                            <v-icon>fal fa-arrow-right</v-icon>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            Varius varius nullam etiam sed scelerisque
                                            <v-icon>fal fa-external-link</v-icon>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            Tempus vitae, nulla rhoncus
                                            <v-icon>fal fa-file-signature</v-icon>
                                        </a>
                                    </li>
                                </ol>
                            </v-flex>
                        </v-card-text>
                    </v-card>
                </v-expansion-panel-content>
                <v-expansion-panel-content :expand-icon="panel == 2 ? 'fal fa-minus' : 'fal fa-plus'">
                    <template v-slot:header>
                        <div class="scl-tab-content__accordion-title">Item 3</div>
                    </template>
                    <v-card>
                        <v-card-text>
                            <v-flex xs12>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </v-flex>
                            <v-flex xs12>
                                <h5>Related resources</h5>
                                <ol>
                                    <li>
                                        <a href="#">
                                            Messa commodo, sed gavida vitae pharetra
                                            <v-icon>fal fa-file-pdf</v-icon>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            Tempus vitae, nulla rhoncus
                                            <v-icon>fal fa-arrow-right</v-icon>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            Varius varius nullam etiam sed scelerisque
                                            <v-icon>fal fa-external-link</v-icon>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            Tempus vitae, nulla rhoncus
                                            <v-icon>fal fa-file-signature</v-icon>
                                        </a>
                                    </li>
                                </ol>
                            </v-flex>
                        </v-card-text>
                    </v-card>
                </v-expansion-panel-content>
                <v-expansion-panel-content :expand-icon="panel == 3 ? 'fal fa-minus' : 'fal fa-plus'">
                    <template v-slot:header>
                        <div class="scl-tab-content__accordion-title">Item 4</div>
                    </template>
                    <v-card>
                        <v-card-text>
                            <v-flex xs12>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </v-flex>
                            <v-flex xs12>
                                <h5>Related resources</h5>
                                <ol>
                                    <li>
                                        <a href="#">
                                            Messa commodo, sed gavida vitae pharetra
                                            <v-icon>fal fa-file-pdf</v-icon>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            Tempus vitae, nulla rhoncus
                                            <v-icon>fal fa-arrow-right</v-icon>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            Varius varius nullam etiam sed scelerisque
                                            <v-icon>fal fa-external-link</v-icon>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            Tempus vitae, nulla rhoncus
                                            <v-icon>fal fa-file-signature</v-icon>
                                        </a>
                                    </li>
                                </ol>
                            </v-flex>
                        </v-card-text>
                    </v-card>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-container>
    </div>
`;

// export template
export default template;
