// component
const component = {
	name: 'SclEnquirePanel',
	data() {
		return {
			formVisible: false
		};
	},
	computed: {
		isAtTop() {
			return this.$root.isAtTop;
		}
	},
	mounted() {
		this.$root.$on('emitFormVisible', val => {
			if (val) {
				this.formVisible = true;
			} else {
				this.formVisible = false;
			}
		});
	},
	methods: {
		scroll() {
			if (this.$root.$refs.formRef) {
				this.$root.$refs['formRef'].$el.scrollIntoView();
			}
		}
	},
	template: null
};

// set template
component.template = require(`./${component.name}-template`).default;

// css import
import(`./_${component.name}.scss`);

// export component
export default component;
