/**
 * SclProductSummary api
 */

// template
const template = `
	<div>
		<v-container grid-list-xl>
			<v-layout row wrap>
				<v-flex xs12 md5 xl4>
					<scl-breadcrumbs v-if="$vuetify.breakpoint.smAndDown" class="scl-component scl-breadcrumbs" :api="{ divider: 'fas fa-chevron-right' }" />
					<h1 v-if="$vuetify.breakpoint.smAndDown" class="scl-product-summary__title">STRAIGHT BOOM LIFT - DIESEL - 26.2M (86FT)</h1>
					<!-- gallery -->
					<scl-gallery-product class="scl-component scl-gallery-product" />
				</v-flex>
				<v-flex xs12 md7 xl8 class="scl-product-summary__container">
					<!-- breadcrumbs -->
					<scl-breadcrumbs v-if="$vuetify.breakpoint.mdAndUp" class="scl-component scl-breadcrumbs" :api="{ divider: 'fas fa-chevron-right' }" />
					<h1 v-if="$vuetify.breakpoint.mdAndUp" class="scl-product-summary__title">STRAIGHT BOOM LIFT - DIESEL - 26.2M (86FT)</h1>
					<div class="scl-product-summary__code">CODE: 10205</div>
					<div class="scl-product-summary__cta-container">
						<scl-button :api="{ href: 'http://www.example.com/', outline: false, copy: 'hire online' }" class="scl-component scl-button"></scl-button>
						<!-- Modal -->
						<scl-enquiry-modal class="scl-component scl-modal"></scl-enquiry-modal>
					</div>
					<div class="scl-product-summary__description">
						<p><scl-dummy-data :content="$root.dummyParagraph" /></p>
						<v-expansion-panel
							v-model="panel"
							class="scl-product-summary__accordion"
						>
							<v-expansion-panel-content hide-actions>
								<template v-slot:header>
									<span>{{ panelContentText }}</span>
								</template>
								<div>
									<p><scl-dummy-data :content="$root.dummyParagraph" /></p>
								</div>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</div>
					<v-list class="scl-product-summary__list">
						<v-list-tile class="scl-product-summary__list-item">
							<v-list-tile-action>
								<v-img contain src="https://www.coateshire.com.au/Coateshire/media/Images/Svg-Images/gloves.svg" width="18" />
							</v-list-tile-action>
							<v-list-tile-content>
								<v-list-tile-title class="scl-product-summary__list-title">ear protection</v-list-tile-title>
							</v-list-tile-content>
						</v-list-tile>
						<v-list-tile class="scl-product-summary__list-item">
							<v-list-tile-action>
								<v-img contain src="https://www.coateshire.com.au/Coateshire/media/Images/Svg-Images/gloves.svg" width="18" />
							</v-list-tile-action>
							<v-list-tile-content>
								<v-list-tile-title class="scl-product-summary__list-title">ear protection</v-list-tile-title>
							</v-list-tile-content>
						</v-list-tile>
						<v-list-tile class="scl-product-summary__list-item">
							<v-list-tile-action>
								<v-img contain src="https://www.coateshire.com.au/Coateshire/media/Images/Svg-Images/gloves.svg" width="18" />
							</v-list-tile-action>
							<v-list-tile-content>
								<v-list-tile-title class="scl-product-summary__list-title">ear protection</v-list-tile-title>
							</v-list-tile-content>
						</v-list-tile>
						<v-list-tile class="scl-product-summary__list-item">
							<v-list-tile-action>
								<v-img contain src="https://www.coateshire.com.au/Coateshire/media/Images/Svg-Images/gloves.svg" width="18" />
							</v-list-tile-action>
							<v-list-tile-content>
								<v-list-tile-title class="scl-product-summary__list-title">ear protection</v-list-tile-title>
							</v-list-tile-content>
						</v-list-tile>
						<v-list-tile class="scl-product-summary__list-item">
							<v-list-tile-action>
								<v-img contain src="https://www.coateshire.com.au/Coateshire/media/Images/Svg-Images/gloves.svg" width="18" />
							</v-list-tile-action>
							<v-list-tile-content>
								<v-list-tile-title class="scl-product-summary__list-title">ear protection</v-list-tile-title>
							</v-list-tile-content>
						</v-list-tile>
						<v-list-tile class="scl-product-summary__list-item">
							<v-list-tile-action>
								<v-img contain src="https://www.coateshire.com.au/Coateshire/media/Images/Svg-Images/gloves.svg" width="18" />
							</v-list-tile-action>
							<v-list-tile-content>
								<v-list-tile-title class="scl-product-summary__list-title">ear protection</v-list-tile-title>
							</v-list-tile-content>
						</v-list-tile>
						<v-list-tile class="scl-product-summary__list-item">
							<v-list-tile-action>
								<v-img contain src="https://www.coateshire.com.au/Coateshire/media/Images/Svg-Images/gloves.svg" width="18" />
							</v-list-tile-action>
							<v-list-tile-content>
								<v-list-tile-title class="scl-product-summary__list-title">ear protection</v-list-tile-title>
							</v-list-tile-content>
						</v-list-tile>
						<v-list-tile class="scl-product-summary__list-item">
							<v-list-tile-action>
								<v-img contain src="https://www.coateshire.com.au/Coateshire/media/Images/Svg-Images/gloves.svg" width="18" />
							</v-list-tile-action>
							<v-list-tile-content>
								<v-list-tile-title class="scl-product-summary__list-title">ear protection</v-list-tile-title>
							</v-list-tile-content>
						</v-list-tile>
					</v-list>
				</v-flex>
			</v-layout>
		</v-container>
	</div>
`;

// export template
export default template;
