// component
const component = {
	name: 'SclDummyData',
	props: {
		content: {
			type: String,
			required: true
		},
		image: {
			type: Boolean,
			required: false
		}
	},
	template: `
		<img v-if="image" class="scl-component scl-dummy-data" alt="dummy data image" :src="content" />
		<span v-else class="scl-component scl-dummy-data" title="dummy data text" v-html="content"></span>
	`
};

// export component
export default component;
