// component
const component = {
	name: 'SclCarouselCardImage',
	template: null,
	props: {
		api: {
			items: []
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					items: []
				},
				this.api
			);
		}
	}
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT) {
	component.template = require(`./${component.name}-template`).default;
}

// css import
import(`./_${component.name}.scss`);

// export component
export default component;
