// component
const component = {
	name: 'SclTabContent',
	data() {
		return {
			activeTab: 'tab-0',
			panel: null,
			players: []
		};
	},
	props: {
		noOfTabs: {
			type: Number
		},
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.centered ? typeof obj.centered === 'boolean' : true));
				set.add(!!(obj.grow ? typeof obj.grow === 'boolean' : true));
				set.add(!!(obj.hideSlider ? typeof obj.hideSlider === 'boolean' : true));
				set.add(!!(obj.icons ? typeof obj.icons === 'boolean' : true));
				set.add(!!(obj.right ? typeof obj.right === 'boolean' : true));
				set.add(!!(obj.disableTouch ? typeof obj.disableTouch === 'boolean' : true));
				set.add(!!(obj.overflowArrows ? typeof obj.overflowArrows === 'boolean' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					centered: false,
					grow: false,
					hideSlider: false,
					icons: false,
					right: false,
					disableTouch: true,
					overflowArrows: false
				},
				this.api
			);
		},
		currentTabIndex() {
			return parseInt(this.activeTab.replace('tab-', ''), 10);
		}
	},
	watch: {
		activeTab() {
			this.stopMediaPlayback();
		},
		panel() {
			this.stopMediaPlayback();
		}
	},
	async mounted() {
		setTimeout(this.initYoutubeIframeOptions, 3000);
		this.navigateTab();
	},
	methods: {
		navigateTab() {
			const params = new URLSearchParams(window.location.search);
			if (params) {
				this.activeTab = params.get('tab');
			}
		},
		prev() {
			if (this.currentTabIndex == 0) {
				this.activeTab = `tab-${this.noOfTabs - 1}`;
				return;
			}
			const prevTab = this.currentTabIndex - 1;
			this.activeTab = `tab-${prevTab}`;
		},
		next() {
			const maxIndex = `tab-${this.noOfTabs - 1}`;
			if (this.activeTab == maxIndex) {
				this.activeTab = `tab-${0}`;
				return;
			}
			const nextTab = this.currentTabIndex + 1;
			this.activeTab = `tab-${nextTab}`;
		},
		initYoutubeIframeOptions() {
			// dynamically gets all iframes with youtube in its id name and creates a list of iframe elements
			const context = this;
			const iframeList = Array.from(document.querySelectorAll('iframe')).filter(p => p.outerHTML.includes('youtube'));

			window.YT.ready(function () {
				if (iframeList.length > 0) {
					iframeList.forEach(p => {
						context.players.push(new window.YT.Player(`${p.id}`));
					});
				}
			});
		},
		stopMediaPlayback() {
			if (this.players.length > 0) {
				this.players.forEach(p => {
					p.stopVideo();
				});
			}
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT) {
	component.template = require(`./${component.name}-template`).default;
}

// css import
require(`./_${component.name}.scss`);

// export component
export default component;
