// component
const component = {
	name: 'SclProductSummary',
	data() {
		return {
			panel: [],
			panelContentText: 'Read More'
		};
	},
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.hideActions ? typeof obj.hideActions === 'boolean' : true));
				set.add(!!(obj.productCode ? typeof obj.productCode === 'string' : true));
				set.add(!!(obj.productName ? typeof obj.productName === 'string' : true));
				set.add(!!(obj.productCategory ? typeof obj.productCategory === 'string' : true));
				set.add(!!(obj.productPrice ? typeof obj.productPrice === 'string' : true));
				return !set.has(false);
			}
		}
	},
	mounted() {
		this.gtmProductDetail();
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					hideActions: false,
					productName: '',
					productCode: '',
					produceCategory: '',
					producePrice: ''
				},
				this.api
			);
		}
	},
	watch: {
		panel(newPanel) {
			this.panelContentText = newPanel === null ? 'Read More' : 'Show Less';
		}
	},
	methods: {
		gtmProductDetail: function () {
			var dataObject = {
				event: 'productDetails',
				ecommerce: {
					detail: {
						actionField: {
							list: this.propApi.productCategory
						},
						products: [
							{
								name: this.propApi.productName,
								id: this.propApi.productCode,
								brand: 'Coates Hire',
								price: this.propApi.productPrice,
								category: this.propApi.productCategory
							}
						]
					}
				}
			};
			if (typeof window.dataLayer != 'undefined') {
				window.dataLayer.push(dataObject);
			}
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT) {
	component.template = require(`./${component.name}-template`).default;
}

// css import
import(`./_${component.name}.scss`);

// export component
export default component;
