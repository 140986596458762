// component
const component = {
	name: 'SclCardImage',
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.overlayTextColour ? typeof obj.variant === 'string' : true));
				set.add(!!(obj.variant ? typeof obj.variant === 'string' : true));
				set.add(!!(obj.textBottom ? typeof obj.textBottom === 'boolean' : true));
				set.add(!!(obj.outline ? typeof obj.outline === 'boolean' : true));
				set.add(!!(obj.bgColour ? typeof obj.bgColour === 'string' : true));
				set.add(!!(obj.isStoryVariant ? typeof obj.isStoryVariant === 'boolean' : true));
				set.add(!!(obj.img ? typeof obj.img === 'string' : true));
				return !set.has(false);
			}
		}
	},
	filters: {
		truncate: function (value, size) {
			if (!value) {
				return '';
			}
			value = value.toString();
			if (value.length <= size) {
				return value;
			}
			return value.substr(0, size) + '...';
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					overlayTextColour: null,
					variant: false,
					textBottom: false,
					outline: null,
					bgColour: null,
					isStoryVariant: false,
					img: null
				},
				this.api
			);
		},
		secondaryBtnCta() {
			return {
				copy: 'Read more',
				outline: true,
				href: '#'
			};
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT) {
	component.template = require(`./${component.name}-template`).default;
}

// css import
import(`./_${component.name}.scss`);

// export component
export default component;
