/**
 * SclPagination
 */

// template
const template = `
	<div>
		<v-container>
			<v-layout row wrap>
				<v-flex xs12>
				 	<div class="scl-pagination__title">Items 1 - 12 of 25</div>
				</v-flex>
				<v-flex xs12>
					<ul class="scl-pagination__list">
						<li class="scl-pagination__nav">
							<a href="#"><v-icon color="accent" size="20">fas fa-chevron-left</v-icon></a>
						</li>
						<li class="scl-pagination__item is-active"><a href="#">1</a></li>
						<li class="scl-pagination__item"><a href="#">2</a></li>
						<li class="scl-pagination__item"><a href="#">3</a></li>
						<li class="scl-pagination__nav">
							<a href="#"><v-icon color="accent" size="20">fas fa-chevron-right</v-icon></a>
						</li>
					</ul>
				</v-flex>
			</v-layout>
		</v-container>
	</div>
`;

// export template
export default template;
