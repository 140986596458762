/**
 * SclSocialShare api
 *
 * type prop
 * @param {string} 'email'
 * @param {string} 'facebook'
 * @param {string} 'linkedin'
 * @param {string} 'pinterest'
 * @param {string} 'twitter'
 **/

const template = `
	<v-btn v-on:click="socialType(api.type)" :class="'scl-social-share--' + api.type" icon large>
		<v-icon>{{ socialIcon(api.type) }}</v-icon>
	</v-btn>
`;

export default template;
