/**
 * SclSpacer api
 *
 **/

const template = `
	<v-container grid-list-xl class="scl-grid-list__container">
		<v-layout row wrap class="scl-grid-list__row">
			<v-flex xs12>
				<div class="scl-component scl-spacer"></div>
			</v-flex>
		</v-layout>
	</v-container>
`;

export default template;
