/**
 * SclProductSubtype
 */

// template
const template = `
	<div>
		<div class="scl-product-subtype__model">
			<!-- model -->
			<div>
				<span class="text-uppercase">model / size</span>
			</div>
			<div>
				<span class="text-uppercase">code</span>
			</div>
			<div>
			</div>
		</div>
		<!-- model -->
		<div class="scl-product-subtype__model">
			<div>
				<span>Water Blaster - 3000PSI - Cold - Diesel</span>
				<v-expansion-panel
					class="scl-product-subtype__accordion"
				>
					<v-expansion-panel-content>
						<template v-slot:header>
							<span>{{ panelContentText }}</span>
						</template>
						<div>
							<ul>
								<li>Length Overall (mm) 1300</li>
								<li>Length Overall (mm) 1300</li>
								<li>Length Overall (mm) 1300</li>
							</ul>
						</div>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</div>
			<div>
				<span>12745</span>
			</div>
			<div>
				<scl-enquiry-modal :btnSmall="true" :api="{ width: '960px', persistent: false }" class="scl-component scl-modal"></scl-enquiry-modal>
			</div>
		</div>
		<!-- model -->
		<div class="scl-product-subtype__model">
			<div>
				<span>Water Blaster - 3000PSI - Cold - Diesel</span>
				<v-expansion-panel
					class="scl-product-subtype__accordion"
				>
					<v-expansion-panel-content>
						<template v-slot:header>
							<span>{{ panelContentText }}</span>
						</template>
						<div>
							<ul>
								<li>Length Overall (mm) 1300</li>
								<li>Length Overall (mm) 1300</li>
								<li>Length Overall (mm) 1300</li>
							</ul>
						</div>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</div>
			<div>
				<span>12745</span>
			</div>
			<div>
				<scl-enquiry-modal :btnSmall="true" :api="{ width: '960px', persistent: false }" class="scl-component scl-modal"></scl-enquiry-modal>
			</div>
		</div>
		<!-- model -->
		<div class="scl-product-subtype__model">
			<div>
				<span>Water Blaster - 3000PSI - Cold - Diesel</span>
			</div>
			<div>
				<span>12745</span>
			</div>
			<div>
				<scl-enquiry-modal :btnSmall="true" :api="{ width: '960px', persistent: false }" class="scl-component scl-modal"></scl-enquiry-modal>
			</div>
		</div>
	</div>
`;

// export template
export default template;
