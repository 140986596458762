// component
const component = {
	name: 'SclCard',
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.description ? typeof obj.description === 'boolean' : true));
				set.add(!!(obj.displayAccordion ? typeof obj.displayAccordion === 'boolean' : true));
				set.add(!!(obj.variant ? typeof obj.variant === 'boolean' : true));
				return !set.has(false);
			}
		}
	},
	data() {
		return {
			panel: [],
			panelContentText: 'View More'
		};
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					description: false,
					displayAccordion: false,
					variant: false
				},
				this.api
			);
		}
	},
	watch: {
		panel(newPanel) {
			this.panelContentText = newPanel === null ? 'View More' : 'Show Less';
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT) {
	component.template = require(`./${component.name}-template`).default;
}

// css import
import(`./_${component.name}.scss`);

// export component
export default component;
