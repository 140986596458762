/**
 * SclFileUpload
 */

// template
const template = `
	<div>
		<div class="scl-file-upload__dragbox">
			<p v-if="$vuetify.breakpoint.smAndUp">Drag and drop files here<br/><span>OR</span></p>
			<file-upload
				ref="upload"
				v-model="files"
				:multiple="true"
				:size="5 * 1024 * 1024"
				:drop="true"
				:headers="{ uploadid : uploadId }"
				name="file"
				post-action="api/form/upload"
				accept="image/png,image/gif,image/jpeg,image/webp,application/pdf"
				@input-file="inputFile"
			>
				<v-btn block class="scl-form__btn scl-button--outline" :outline="true" color="accent" depressed height="42">Select Files</v-btn>
			</file-upload>
			<div v-show="$refs.upload && $refs.upload.dropActive" class="drop-active">
				<h3>Drop files to upload</h3>
			</div>
		</div>
		<ul v-if="files.length" class="scl-file-upload__filelist">
			<li v-for="(file, index) in files" :key="file.id">
			<span>{{file.name}}</span> -
			<span v-if="file.error=='size'">ERROR File is over maximum 5MB size limit.</span>
			<span v-else-if="file.error">ERROR {{file.error}}</span>
			<span v-else-if="file.success">success</span>
			<span v-else-if="file.active">active</span>
			<span v-else></span>
			</li>
		</ul>
	</div>
`;

// export template
export default template;
