/**
 * SclTabs api
 *
 * @param {boolean} centered - centers the tabs
 * @param {boolean} disableTouch - disables swiping of tab content
 * @param {boolean} grow - stretch the tabs to take up all horizontal space
 * @param {boolean} hideSlider - hides the slider underneath tabs
 * @param {boolean} icons - will display icons above the tabs
 * @param {boolean} overflowArrows - shows arrow when tabs dont fit within its parent container
 * @param {boolean} right - aligns tabs to the right
 */

// template
const template = `
	<scl-tabs
		inline-template
		:api="{ centered: false, disableTouch: false, grow: false, hideSlider: true, icons: true, overflowArrows: false, right: true }"
		class="scl-component scl-tabs"
	>
		<div>
			<v-tabs
				v-model="activeTab"
				:centered="propApi.centered"
				:grow="propApi.grow"
				:hide-slider="propApi.hideSlider"
				:icons-and-text="propApi.icons"
				:right="propApi.right"
				:show-arrows="propApi.overflowArrows"
				color="primary"
				slider-color="secondary"
				dark
			>
				<v-tab href="#tab-1">
					Item 1
					<v-icon v-if="propApi.icons">far fa-phone</v-icon>
				</v-tab>
				<v-tab href="#tab-2">
					Item 2
					<v-icon v-if="propApi.icons">far fa-phone</v-icon>
				</v-tab>
				<v-tab href="#tab-3">
					Item 3
					<v-icon v-if="propApi.icons">far fa-phone</v-icon>
				</v-tab>
				<v-tab href="#tab-4">
					Item 4
					<v-icon v-if="propApi.icons">far fa-phone</v-icon>
				</v-tab>
				<v-tab href="#tab-5">
					Item 5
					<v-icon v-if="propApi.icons">far fa-phone</v-icon>
				</v-tab>
				<v-tab href="#tab-6">
					Item 6
					<v-icon v-if="propApi.icons">far fa-phone</v-icon>
				</v-tab>
			</v-tabs>

			<v-tabs-items v-model="activeTab" :touchless="propApi.disableTouch">
				<v-tab-item value="tab-1">{{ $root.dummyParagraph }}</v-tab-item>
				<v-tab-item value="tab-2">{{ $root.dummyParagraph }}</v-tab-item>
				<v-tab-item value="tab-3">{{ $root.dummyParagraph }}</v-tab-item>
				<v-tab-item value="tab-4">{{ $root.dummyParagraph }}</v-tab-item>
				<v-tab-item value="tab-5">{{ $root.dummyParagraph }}</v-tab-item>
				<v-tab-item value="tab-6">{{ $root.dummyParagraph }}</v-tab-item>
			</v-tabs-items>
		</div>
	</scl-tabs>
`;

// export template
export default template;
