/**
 * SclGridList
 *
 * background prop
 * @param {boolean} false
 *
 * titleCenter prop
 * @param {boolean} false
 *
 * cta prop
 * @param {boolean} true
 */

// tempalte
const template = `
	<div :class="{'scl-grid-list--bg' : propApi.background }">
		<v-container grid-list-xl class="scl-grid-list__container">
			<v-layout row wrap class="scl-grid-list__row">
				<v-flex xs12>
					<h2 class="scl-grid-list__title" :class="{'text-xs-center' : propApi.titleCenter }"><scl-dummy-data :content="$root.dummyWords"></scl-dummy-data></h2>
				</v-flex>
				<v-flex xs12 sm6 md4>
					<scl-card-image :api="{ href: 'http://www.example.com/', textBottom: true, title: 'Lorem ipsum dolor sit amet consectetur adipiscing', subtitle: 'Lorem ipsum', img: 'https://picsum.photos/700/965' }" class="scl-component scl-card-image"></scl-card-image>
				</v-flex>
				<v-flex xs12 sm6 md4>
					<scl-card-solid :api="{ href: 'http://www.example.com/', img: 'https://picsum.photos/700/965', title: 'Lorem ipsum dolor', icon: 'fas fa-truck-pickup' }" class="scl-component scl-card-solid"></scl-card-solid>
				</v-flex>
				<v-flex xs12 sm6 md4>
					<scl-card-icon :api="{ href: 'http://www.example.com/', copy: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur excepteur sint occaecat cupidatat non proident sunt in culpa qui officia deserunt mollit anim id est laborum.', title: 'Lorem ipsum dolor', icon: 'fas fa-truck-pickup' }" class="scl-component scl-card-icon"></scl-card-icon>
				</v-flex>
				<v-flex xs12>
				<scl-carousel-card :api="{items:[{href: 'http://www.example.com/', textBottom: false, title: 'Lorem ipsum dolor sit amet consectetur adipiscing', subtitle: 'Lorem ipsum', img: 'https://picsum.photos/700/965' }, {textBottom: true, title: 'Lorem ipsum dolor sit amet consectetur adipiscing', subtitle: 'Lorem ipsum', img: 'https://picsum.photos/700/965' }]}" class="scl-component scl-carousel-card"></scl-carousel-card>
				</v-flex>
				<v-flex xs12 sm6 md4>
					<scl-card :api="{ displayAccordion: true }" class="scl-component scl-card"></scl-card>
				</v-flex>
				<v-flex xs12 sm6 md4>
					<scl-card-cta class="scl-component scl-card-cta"></scl-card-cta>
				</v-flex>
				<v-flex xs12 sm6 md4>
					<scl-card-solid :api="{ href: 'http://www.example.com/', img: 'https://picsum.photos/700/965', title: 'Lorem ipsum dolor', icon: 'fas fa-truck-pickup', desc: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur excepteur sint occaecat cupidatat.' }" class="scl-component scl-card-solid"></scl-card-solid>
				</v-flex>
				<v-flex v-if="propApi.cta.visible" xs12 class="scl-grid-list__btn-container">
					<scl-button :api="propApi.cta.api" class="scl-component scl-button"></scl-button>
				</v-flex>
			</v-layout>
		</v-container>
	</div>
`;

// export template
export default template;
