/**
 * SclBannerDescriptor
 *
 * bgImage prop
 * @param {string} 'http://www.example.com'
 *
 * imageTagText prop
 * @param {string} 'Technology'
 *
 * headerText prop
 * @param {string} 'Lorem ipsum'
 *
 * descriptorText prop
 * @param {string} 'Lorem ipsum talke beiolgical'
 *
 * dropDownSelector prop
 * @param {string} [{copy: 'dropdown name', primaryCta: 'http://www.example.com'}]
 *
 * tertiaryCta prop
 * @param {string} 'https://picsum.photos/700/965'
 */

// template
const template = `
	<div class="scl-banner-descriptor">
		<v-container grid-list-xl>
			<v-layout row wrap>
				<v-flex sm12 class="scl-banner-descriptor__wrapper">
					<div
						class="scl-banner-descriptor__container"
						:style="{'background': 'linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), url(' + propApi.bgImage + ')'}">
						<div class="scl-banner-descriptor__overlay"></div>

						<div class="scl-banner-descriptor__image-tag">
							<p class="scl-banner-descriptor__image-tag-name">{{ propApi.imageTagText }}</p>
						</div>

						<div class="scl-banner-descriptor__tertiary-cta">
							<scl-button class="scl-component scl-button scl-button--tertiary" :api="{
								copy: 'Go to ' + propApi.imageTagText,
								outline: false,
								href: propApi.tertiaryCta,
							}"
							></scl-button>
							<v-icon class="scl-banner-descriptor__tertiary-cta-icon">fas fa-arrow-right</v-icon>
						</div>

						<h3 class="scl-banner-descriptor__header-text">{{ propApi.headerText }}</h3>

						<p class="scl-banner-descriptor__descriptor-text">{{ propApi.descriptorText }}</p>

						<div class="scl-banner-descriptor__dropdown-container">
							<v-select
								v-model="selectedLink"
								:items="propApi.dropDownSelector"
								item-text="name"
								item-value="primaryCta"
								append-icon="fas fa-chevron-down"
								class="scl-banner-descriptor__selection"
								attach="scl-banner-descriptor__content"
								single-line
								@change="selectChange"
							></v-select>

							<scl-button class="scl-component scl-button scl-banner-descriptor__primary-cta" :api="{
								copy: 'Let’s go',
								outline: false,
								href: selectedLink,
								icon: 'fas fa-arrow-right'
							}"
							></scl-button>
						</div>
					</div>
				</v-flex>
			</v-layout>
		</v-container>
	</div>
`;

// export template
export default template;
