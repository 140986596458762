// component
const component = {
	name: 'SclCardNews',
	template: null,
	props: {
		api: {
			type: Object,
			required: false
		}
	},
	computed: {
		primaryBtnApi() {
			return {
				copy: 'Find out more',
				outline: false,
				icon: 'fal fa-arrow-right'
			};
		}
	}
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT) {
	component.template = require(`./${component.name}-template`).default;
}

// css import
import(`./_${component.name}.scss`);

// export component
export default component;
