/**
 * SclExample api
 *
 * boolean prop
 * @param {boolean} true
 *
 * number prop
 * @param {number} 12
 *
 * string prop
 * @param {string} string API example
 */

// template
const template = `
	<scl-example inline-template :api="{ boolean: true, number: 12, string: 'string API example' }" class="scl-component scl-example">
		<v-layout>
			<v-flex xs12 sm6 offset-sm3>
				<v-card>
					<v-img :lazy-src="$root.dummyImage43" :src="$root.dummyImage43" aspect-ratio="1.33">
						<template v-slot:placeholder>
							<v-layout
								fill-height
								align-center
								justify-center
								ma-0
							>
								<v-progress-circular indeterminate color="black"></v-progress-circular>
							</v-layout>
						</template>
					</v-img>
					<v-card-title primary-title>
						<div>
							<h3 class="headline mb-3">Prop API JS Data Type Examples</h3>
							<div>
								<p v-if="propApi.boolean">boolean API example - visible if true</p>
								<p>{{ propApi.number }} - number API example</p>
								<p>{{ propApi.string }}</p>
							</div>
						</div>
					</v-card-title>
					<v-card-actions>
						<v-btn flat color="blue">{{ jsMessage }}</v-btn>
					</v-card-actions>
				</v-card>
			</v-flex>
		</v-layout>
	</scl-example>
`;

// export template
export default template;
