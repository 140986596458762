// component
const component = {
	name: 'SclCardLinkSelector',
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.dropdown ? typeof obj.dropdown === 'object' : true));
				return !set.has(false);
			}
		}
	},
	data() {
		return {
			selectedLink: null
		};
	},
	mounted() {
		this.selectedLink = this.propApi.dropDownSelector[0].url;
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					dropdown: []
				},
				this.api
			);
		},
		primaryBtnApi() {
			return {
				copy: 'Let’s go',
				outline: false,
				href: this.selectedLink,
				icon: 'fal fa-arrow-right'
			};
		}
	},
	methods: {
		selectChange(e) {
			this.selectedLink = e;
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT) {
	component.template = require(`./${component.name}-template`).default;
}

// css import
import(`./_${component.name}.scss`);

// export component
export default component;
