/**
 * SclBannerBranch
 */

// template
const template = `
	<div>
		<v-container>
		<h5>There are 145+ branches located across Australia. Find your nearest branch below.</h5>
			<v-layout>
				<v-flex xs12>
					<h1 class="scl-banner__title">find a branch</h1>
				</v-flex>
			</v-layout>
			<v-layout row wrap>
				<v-flex xs12 md5>
					<v-autocomplete
						v-model="model"
						:items="items"
						background-color="white"
						color="primary"
						class="scl-banner-autocomplete__input"
						hide-no-data
						item-text="Description"
						item-value="API"
						placeholder="Enter postcode or suburb"
						return-object
						auto-select-first
						:loading="isLoading"
						cache-items
					>
					</v-autocomplete>
				</v-flex>
				<v-flex xs12 md6>					
					<v-btn class="scl-banner-autocomplete__search-btn" color="accent" depressed href="#">Search</v-btn>
				</v-flex>
				<v-flex xs12 md6>
					<v-btn :disabled="locationBtnDisabled" depressed outline class="scl-component scl-button scl-button--outline" @click="getLocation()">
						{{ locationText }}
					</v-btn>
				</v-flex>
			</v-layout>
		</v-container>
	</div>
`;

// export template
export default template;
