// component
const component = {
	name: 'SclContactForm',
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.enquiryType ? typeof obj.enquiryType === 'string' : true));
				return !set.has(false);
			}
		}
	},
	data() {
		return {
			dialog: false,
			items: ['General', 'Equipment', 'Feedback', 'Accounts', 'Careers', 'Training'],
			entries: [],
			search: null,
			firstName: '',
			lastName: '',
			email: '',
			phone: '',
			company: '',
			isLoading: false,
			location: '',
			subject: '',
			enquiry: '',
			storeName: '',
			valid: false,
			enquiryResult: null,
			attachmentUrl: '',
			rules: {
				required: value => !!value || 'Required',
				counter50: value => value.length <= 50 || 'Max 50 characters',
				counter500: value => value.length <= 500 || 'Max 500 characters',
				lettersOnly: value => {
					const lettersPattern = /^[a-zA-Z\s'-]+$/;
					return lettersPattern.test(value) || 'Name is invalid';
				},
				australianPhoneNumber: value => {
					const pattern = /^0([0-9]{9})$/;
					return pattern.test(value) || 'Phone number is invalid';
				},
				email: value => {
					const pattern =
						/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
					return pattern.test(value) || 'Please enter a valid email address';
				}
			}
		};
	},
	watch: {
		isValid() {
			this.valid = this.$refs.form.validate();
		},
		search(val) {
			if (this.entries.length > 0) {
				return;
			}
			if (this.isLoading) {
				return;
			}
			if (val && val.length > 3) {
				this.isLoading = true;
				window
					.fetch('/assets/data/suburbs.json')
					.then(res => res.json())
					.then(res => {
						this.entries = res;
					})
					.catch(err => {
						console.error(err);
					})
					.finally(() => (this.isLoading = false));
			}
		},
		location(val) {
			if (val) {
				window
					.fetch(process.env.VUE_APP_CLOSESTBRANCH_API + '?latitude=' + val.Latitude + '&longitude=' + val.Longitude, {
						headers: {
							'Content-Type': 'application/json'
						}
					})
					.then(res => res.json())
					.then(res => {
						if (res.errorMessage) {
							console.error('closest branch lookup error: ' + res.errorMessage);
						}
						this.storeName = res.storeCode;
					});
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					enquiryType: ''
				},
				this.api
			);
		},
		locationItems() {
			return this.entries.map(entry => {
				const Description = `${entry.SuburbName} ${entry.Postcode} ${entry.StateCode}`;
				return Object.assign({}, entry, { Description });
			});
		}
	},
	mounted() {
		this.$root.$on('emitfileuploadevent', response => {
			if (response && response.success) {
				this.attachmentUrl += response.fileName + ',';
			}
		});
		const observer = new IntersectionObserver(entries => {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					this.$root.$emit('emitFormVisible', true);
				} else {
					this.$root.$emit('emitFormVisible', false);
				}
			});
		});
		observer.observe(this.$root.$refs['formRef'].$el);
	},
	methods: {
		clear() {
			this.location = null;
		},
		resetForm() {
			this.$refs.form.reset();
		},
		submitForm() {
			if (!this.$refs.form.validate()) {
				return;
			}
			window.scrollTo(0, 0);
			if (this.propApi.enquiryType.length) {
				this.subject = this.propApi.enquiryType;
			}
			const contactuspageurl = new URL(window.location.href);
			const utm_source = contactuspageurl.searchParams.get('utm_source');
			const utm_medium = contactuspageurl.searchParams.get('utm_medium');
			const utm_campaign = contactuspageurl.searchParams.get('utm_campaign');
			const utm_content = contactuspageurl.searchParams.get('utm_content');
			const utm_term = contactuspageurl.searchParams.get('utm_term');

			let utmParams =
				(utm_source ? 'utm_source=' + encodeURIComponent(utm_source) : '') +
				(utm_medium ? '&utm_medium=' + encodeURIComponent(utm_medium) : '') +
				(utm_campaign ? '&utm_campaign=' + encodeURIComponent(utm_campaign) : '') +
				(utm_content ? '&utm_content=' + encodeURIComponent(utm_content) : '') +
				(utm_term ? '&utm_term=' + encodeURIComponent(utm_term) : '');
			if (utmParams.length > 0 && utmParams.charAt(0) === '&') {
				utmParams = utmParams.substring(1);
			}
			var formData = {
				pageUrl: window.location.href,
				firstName: this.firstName,
				lastName: this.lastName,
				email: this.email,
				phone: this.phone,
				company: this.company,
				location: this.location.Description,
				postcode: this.location.Postcode,
				state: this.location.StateCode,
				suburb: this.location.SuburbName,
				storeName: this.storeName,
				subject: this.subject,
				enquiry: this.enquiry,
				attachmentUrl: this.attachmentUrl,
				utm: utmParams
			};
			this.resetForm();
			var url = process.env.VUE_APP_CONTACTFORM_API;
			document.querySelectorAll('.v-dialog')[0].getElementsByTagName('legend')[0].innerHTML = 'Processing...';
			document.querySelectorAll('.v-dialog')[0].getElementsByTagName('h4')[0].innerHTML = '';
			this.dialog = true;
			fetch(url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(formData)
			})
				.then(response => {
					if (response.ok) {
						document.querySelectorAll('.v-dialog')[0].getElementsByTagName('legend')[0].innerHTML = 'Your enquiry has been submitted';
						document.querySelectorAll('.v-dialog')[0].getElementsByTagName('h4')[0].innerHTML =
							'Thank you for your enquiry. One of our team members will get back to you as soon as possible.';
						this.dialog = true;
					} else {
						let h4text = 'Form submission failed. Please try again later';
						if (contactuspageurl.pathname.includes('industrial-solutions')) {
							h4text =
								"Form submission failed. Please try again later, or send your enquiry to <a href='mailto:industrialsolutions@coates.com.au'>industrialsolutions@coates.com.au</a>";
						} else if (contactuspageurl.pathname.includes('engineering-solutions')) {
							h4text =
								"Form submission failed. Please try again later, or send your enquiry to <a href='mailto:engineeringsolutions@coates.com.au'>engineeringsolutions@coates.com.au</a>";
						} else if (contactuspageurl.pathname.includes('power-and-hvac')) {
							h4text =
								"Form submission failed. Please try again later, or send your enquiry to <a href='mailto:powerandhvac@coates.com.au'>powerandhvac@coates.com.au</a>";
						}

						document.querySelectorAll('.v-dialog')[0].getElementsByTagName('legend')[0].innerHTML = 'Error';
						document.querySelectorAll('.v-dialog')[0].getElementsByTagName('h4')[0].innerHTML = h4text;
						this.dialog = true;
						return;
					}

					var data = response.json();
					if (data) {
						this.enquiryResult = data;
					}
				})
				.catch(error => {
					console.error('Error:', error);
				});
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT) {
	component.template = require(`./${component.name}-template`).default;
}

// css import
import(`./_${component.name}.scss`);

// export component
export default component;
