/**
 * SclCarouselCardImage
 */

// template
const template = `
	<div>
		<!-- grid to contain carousel -->
		<v-container v-if="$vuetify.breakpoint.xsOnly">
			<v-layout row wrap>
				<v-flex xs12>
					<!-- vuetify carousel wrapper -->
					<v-carousel class="scl-carousel-card__carousel" hide-delimiters interval="7000" :cycle="false">
						<!-- mobile carousel -->
						<v-carousel-item v-for="item in propApi.items" :key="item.title">
							<v-container grid-list-xl class="px-0">
								<v-layout>
									<v-flex xs12>
										<scl-card-image :api="{ href: item.href, textBottom: item.textBottom, title: item.title, subtitle: item.subtitle, img: item.img }" class="scl-component scl-card-image"></scl-card-image>
									</v-flex>
								</v-layout>
							</v-container>
						</v-carousel-item>
						</v-carousel>
				</v-flex>
			</v-layout>
		</v-container>
	</div>
`;

// export template
export default template;
