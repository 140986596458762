// component
const component = {
	name: 'SclGridList',
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.background ? typeof obj.background === 'boolean' : true));
				set.add(!!(obj.cta ? typeof obj.cta === 'object' : true));
				set.add(!!(obj.titleCenter ? typeof obj.titleCenter === 'boolean' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					background: false,
					cta: { visible: true },
					titleCenter: false
				},
				this.api
			);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT) {
	component.template = require(`./${component.name}-template`).default;
}

// css import
import(`./_${component.name}.scss`);

// export component
export default component;
