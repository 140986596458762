/**
 * SclSubscribePanel api
 *
 */

// template
const template = `
	<div>
        <v-dialog
            v-model="dialog"
			persistent
			:width="$vuetify.breakpoint.smAndDown ? '100%' : '768'"
        >
        <v-card class="fill-height">
					<div class="scl-subscribe-modal__header">
                            <v-icon class="scl-subscribe-modal__header-icon" size="36" @click="dialog = false">far fa-times</v-icon>
                    </div>
					<div class="scl-subscribe-modal__content">
                                <h4 class="scl-subscribe-modal__sub-title">Sign up to our newsletter</h4>
                                <scl-subscribe-form inline-template class="scl-component scl-subscribe-form scl-subscribe-form__variant "
                                :api="{
                                    variant: true
                                }"
                                >
                                    <v-form v-if="propApi.variant && !success" v-model="valid" ref="subscribeForm">
                                        <p >Get the latest business and industry news delivered direct. We promise we won’t overload your inbox.</p>
                                        <div class="scl-subscribe-form__variant--wrapper">
                                            <v-text-field
                                                v-model="emailAddress"
                                                :rules="emailRules"
                                                :validate-on-blur="true"
                                                placeholder="Enter email"
                                                class="scl-component scl-input scl-subscribe-form__variant--input pt-2 mt-0"
                                                required
                                            ></v-text-field>
                                            <v-btn depressed class="scl-component scl-button scl-subscribe-form__variant--btn"
                                                :outline="false"
                                                :class="{'scl-button--outline' : propApi.outline }"
                                                @click="subscribe"
                                            >
                                                Subscribe <v-icon class="scl-button__icon scl-subscribe-form__variant--icon">fal fa-paper-plane</v-icon>
                                            </v-btn>
                                        </div>
                                        <span class="error--text" v-if="!success && error">@Html.Glass().Editable(Model, n => n.Error_Message)</span>
                                        <p class="scl-subscribe-form__variant--terms">By subscribing to Coates you agree to our <a :href="$root.dummyUrl" class="scl-subscribe-form__variant--terms-link">{{ $root.dummyWord }}</a></p>
                                    </v-form>
                                    <div v-else-if="propApi.variant && success">
                                        <p class="scl-subscribe-form__variant--success-message">Thank you.<p>
                                    </div>
                                </scl-subscribe-form>
                    </div>
				</v-card>
        </v-dialog>
	</div>
`;

// export template
export default template;
