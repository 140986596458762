// component
const component = {
	name: 'SclBannerSearch',
	data() {
		return {
			entries: [],
			model: null,
			search: null,
			isLoading: false,
			imgHeight: 'auto'
		};
	},
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.bgImages ? Array.isArray(obj.bgImages) : true));
				set.add(!!(obj.cycle ? typeof obj.cycle === 'boolean' : true));
				set.add(!!(obj.delimiterIcon ? typeof obj.delimiterIcon === 'string' : true));
				set.add(!!(obj.hideDelimiter ? typeof obj.hideDelimiter === 'boolean' : true));
				set.add(!!(obj.hideControls ? typeof obj.hideControls === 'boolean' : true));
				set.add(!!(obj.interval ? typeof obj.interval === 'number' : true));
				set.add(!!(obj.nextIcon ? typeof obj.nextIcon === 'string' : true));
				set.add(!!(obj.prevIcon ? typeof obj.prevIcon === 'string' : true));
				set.add(!!(obj.color ? typeof obj.color === 'string' : true));
				set.add(!!(obj.gradient ? typeof obj.gradient === 'boolean' : true));
				return !set.has(false);
			}
		}
	},
	mounted() {
		const originalOnKeyDown = this.$refs.bannerSearchAutoComplete.onKeyDown;
		this.$refs.bannerSearchAutoComplete.onKeyDown = e => (e.keyCode === 13 ? this.searchLink() : originalOnKeyDown(e));
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					bgImages: [],
					cycle: true,
					delimiterIcon: this.$vuetify.icons.delimiter,
					hideDelimiter: false,
					hideControls: false,
					interval: 5000,
					nextIcon: this.$vuetify.icons.next,
					prevIcon: this.$vuetify.icons.prev,
					color: '#0000ff',
					gradient: false
				},
				this.api
			);
		},
		items() {
			return this.entries.map(entry => {
				const Name = `${entry.name}`;
				const Url = `${entry.url}`;
				const Keywords = `${entry.keywords}`;
				return Object.assign({}, entry, { Name, Url, Keywords });
			});
		}
	},
	created() {
		this.calcImgHeight();
	},
	watch: {
		search(val) {
			// Items have already been loaded
			if (this.items.length > 0) {
				return;
			}
			// Items have already been requested
			if (this.isLoading) {
				return;
			}
			if (val && val.length > 3) {
				this.isLoading = true;
				window
					.fetch(process.env.VUE_APP_PRODUCTSEARCH_API)
					.then(res => res.json())
					.then(res => {
						this.entries = res;
					})
					.catch(err => {
						console.error(err);
					})
					.finally(() => (this.isLoading = false));
			}
		},
		model(newModel) {
			window.location.href = newModel.Url;
		}
	},
	methods: {
		clear() {
			this.model = null;
		},
		customFilter(item, queryText) {
			const searchValues = (item.name + ' ' + item.keywords).toLowerCase();
			const searchTextValues = queryText.toLowerCase().split(' ');
			return searchTextValues.every(r => searchValues.includes(r));
		},
		calcImgHeight() {
			if (this.$vuetify.breakpoint.xsOnly) {
				this.imgHeight = '360';
			}
			if (this.$vuetify.breakpoint.smOnly || this.$vuetify.breakpoint.mdOnly) {
				this.imgHeight = '496';
			}
			if (this.$vuetify.breakpoint.lgAndUp) {
				this.imgHeight = '624';
			}
		},
		searchLink() {
			var searchurl = this.$attrs['data-searchurl'];
			if (this.search) {
				searchurl = searchurl + '?term=' + this.search.replace(/\s/g, '-');
			}
			window.location.href = searchurl;
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT) {
	component.template = require(`./${component.name}-template`).default;
}

// css import
import(`./_${component.name}.scss`);

// export component
export default component;
