import { gmapApi } from 'vue2-google-maps';
// component
const component = {
	name: 'SclMap',
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.latitude ? typeof obj.latitude === 'number' : true));
				set.add(!!(obj.longitude ? typeof obj.longitude === 'number' : true));
				set.add(!!(obj.zoom ? typeof obj.zoom === 'boolean' : true));
				return !set.has(false);
			}
		},
		itemsPerSlide: {
			type: Number,
			default: 3
		},
		singleWidth: Number,
		zoom: {
			type: Number,
			default: 15
		},
		slideStyleMarginLeft: Number
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					latitude: -25.61,
					longitude: 134.35472222,
					zoom: false
				},
				this.api
			);
		},
		google: gmapApi,
		slidesInnerMarginLeft() {
			if (!this.isMobileDevice && this.currentIndex > 0) {
				return this.currentIndex * (this.singleWidth + this.slideStyleMarginLeft);
			}
			return 0;
		},
		nextNavDisabled() {
			return this.currentIndex >= this.filteredBranches.length - this.itemsPerSlide;
		},
		prevNavDisabled() {
			return this.currentIndex <= 0;
		}
	},
	data() {
		return {
			filteredBranches: [],
			branches: [],
			hasErrored: false,
			hasSidebar: false,
			loadingBranches: false,
			infoWindow: {
				branch: {},
				isOpen: false
			},
			map: {
				clusterStyles: [
					{
						textColor: 'white',
						url: '/assets/img/map-cluster.png',
						height: 40,
						width: 40,
						textSize: 16
					}
				],
				mapCenter: {
					// prettier-ignore
					lat: -25.61000000,
					// prettier-ignore
					lng: 134.35472222
				},
				mapZoom: this.$vuetify.breakpoint.mdAndDown ? 4 : 5
			},
			bounds: null,
			currentIndex: 0,
			// the width of all the slides
			innerWidth: '',
			// the visible window of the slides
			slideWidth: '',
			// the actual single slide width
			singleSlideWidth: '',
			isMobileDevice: false
		};
	},
	created() {
		this.fetchData();
		window.addEventListener('resize', this.myEventHandler);
		console.log('resize attached');
	},
	destroyed() {
		window.removeEventListener('resize', this.myEventHandler);
	},
	mounted() {
		this.isMobileDevice = window.innerWidth <= 1024;
		// listen for mapfilter event
		this.$root.$on('mapFilterEvent', event => {
			this.map.mapCenter.lat = this.generateLatLng(event.mapCenter.lat);
			this.map.mapCenter.lng = this.generateLatLng(event.mapCenter.lng);
			this.map.mapZoom = this.$vuetify.breakpoint.mdAndDown ? 9 : 10;
			this.$refs.mapRef.$mapPromise.then(() => {
				this.getMarkers();
			});
		});

		//when propApi.zoom is true, it means we want to zoom to the passed in location (lat/lng), instead of the default map loc
		if (this.propApi.zoom == true) {
			this.$root.$emit('mapFilterEvent', {
				mapCenter: {
					lat: this.propApi.latitude,
					lng: this.propApi.longitude
				}
			});
		}
	},
	watch: {
		loadingBranches(val) {
			if (val) {
				this.getMarkers();
			}
		},
		filteredBranches: {
			handler: function () {
				this.calculateWidths();
			},
			deep: true
		},
		isMobileDevice: {
			handler: function () {
				this.calculateWidths();
				console.log('isMobileDevice handler: function');
			}
		}
	},
	methods: {
		// create directions and position
		createKeys(arr) {
			arr.forEach(el => {
				el.directions = `https://maps.google.com/?saddr=Current+Location&daddr=${el.StoreAddress}, ${el.StoreSuburb}, ${el.StoreState}, ${el.StorePostcode}`;
				el.position = {
					lat: this.generateLatLng(el.StoreLatitude),
					lng: this.generateLatLng(el.StoreLongitude)
				};
			});
			return arr;
		},
		fetchData() {
			// branches have already been loaded
			if (this.branches.length > 0) {
				return;
			}
			// branches have already been requested
			if (this.loadingBranches) {
				return;
			}
			this.loadingBranches = true;
			// get branches
			window
				.fetch(process.env.VUE_APP_STORES_API)
				.then(res => res.json())
				.then(res => {
					this.branches = this.createKeys(res.Data);
				})
				.catch(err => {
					this.hasErrored = true;
					console.error(err);
				})
				.finally(() => (this.loadingBranches = false));
		},
		generateLatLng(val) {
			const latLng = parseFloat(val).toFixed(10);
			return Number(latLng);
		},
		getMarkers() {
			if (this.loadingBranches) {
				return;
			}
			this.filteredBranches = [];
			this.filterBranchesByDistance(50000);
			this.setSidebar();
		},
		filterBranchesByDistance(distance) {
			let closestBranch = this.branches[0];
			this.$refs.mapRef.$mapPromise.then(map => {
				this.filteredBranches = [];
				this.branches.forEach(branch => {
					let branchPosition = {};
					branchPosition = {
						position: branch.position
					};
					const marker = new window.google.maps.Marker(branchPosition);
					branch.distance = this.google.maps.geometry.spherical.computeDistanceBetween(marker.getPosition(), map.getCenter());
					if (branch.distance < distance) {
						this.filteredBranches.push(branch);
					} else if (closestBranch.distance > branch.distance) {
						closestBranch = branch;
					}
				});
				if (this.filteredBranches.length == 0) {
					this.filteredBranches.push(closestBranch);
				}
				this.filteredBranches.sort(function (a, b) {
					return a.distance > b.distance ? 1 : b.distance > a.distance ? -1 : 0;
				});
			});
		},
		setSidebar() {
			this.hasSidebar = true;
		},
		updateMapPosition() {
			this.$refs.mapRef.$on('dragend', () => {
				this.$nextTick(() => {
					this.map.mapCenter.lat = this.$refs.mapRef.$mapObject.getCenter().lat();
					this.map.mapCenter.lng = this.$refs.mapRef.$mapObject.getCenter().lng();
					this.getMarkers();
				});
			});
		},
		goToPrev() {
			if (this.currentIndex >= this.itemsPerSlide - this.itemsPerSlide) {
				this.currentIndex--;
			}
		},
		goToNext() {
			if (this.currentIndex < this.filteredBranches.length - this.itemsPerSlide) {
				this.currentIndex++;
			}
		},
		myEventHandler() {
			this.isMobileDevice = window.innerWidth <= 1024;
			console.log('myEventHandler called');
		},
		calculateWidths() {
			this.slideWidth = '';
			this.innerWidth = '';
			this.singleSlideWidth = '';

			if (!this.isMobileDevice) {
				this.currentIndex = 0;
				let length = this.filteredBranches.length;
				if (this.itemsPerSlide > length) {
					length = this.itemsPerSlide;
				}
				this.innerWidth = this.singleWidth * length + this.slideStyleMarginLeft * (length - 1) + 10 + 'px';
				this.slideWidth = this.singleWidth * this.itemsPerSlide + this.slideStyleMarginLeft * (this.itemsPerSlide - 1) + 10 + 'px';
				this.singleSlideWidth = this.singleWidth + 'px';
				this.singleSlideWidth = this.singleWidth + 'px';
			}
		}
	},
	template: null
};

component.template = require(`./${component.name}-template`).default;

// css import
import(`./_${component.name}.scss`);

// export component
export default component;
