// component
const component = {
	name: 'SclButton',
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.href ? typeof obj.href === 'string' : true));
				set.add(!!(obj.outline ? typeof obj.outline === 'boolean' : true));
				set.add(!!(obj.copy ? typeof obj.copy === 'string' : true));
				set.add(!!(obj.icon ? typeof obj.icon === 'string' : true));
				set.add(!!(obj.type ? typeof obj.type === 'string' : true));
				set.add(!!(obj.target ? typeof obj.target === 'string' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					href: null,
					outline: true,
					copy: null,
					icon: null,
					type: 'button',
					target: null
				},
				this.api
			);
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
if (process.env.VUE_APP_DEVELOPMENT) {
	component.template = require(`./${component.name}-template`).default;
}

// css import
import(`./_${component.name}.scss`);

// export component
export default component;
