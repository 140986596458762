/**
 * SclAlert api
 *
 * icon prop
 * @param {string} 'fas fa-exclamation-triangle'
 * @param {string} 'fas fa-info-circle'
 * @param {string} 'fas fa-check-circle'
 * @param {string} 'fas fa-exclamation'
 *
 * type prop
 * @param {string} 'error'
 * @param {string} 'info'
 * @param {string} 'success'
 * @param {string} 'warning'
 * @param {string} 'none'
 *
 * dismissible prop
 * @param {boolean} true || false
 *
 * outline prop
 * @param {boolean} true || false
 */

// template
const template = `
	<div v-if="isDismissed" :class="propApi.type">
		<v-container class="py-0">
			<v-alert
				:dismissible="propApi.dismissible"
				:outline="propApi.outline"
				:icon="propApi.icon"
				:type="propApi.type"
				:value="true"
				class="scl-alert__alert px-0"
				@input="dismiss"
			>
				<div class="scl-alert__content">
					<div class="scl-alert__content-copy">{{ $root.dummySentence }}
					</div>
					<a class="scl-alert__cta" :href="$root.dummyUrl" target="_blank">quis nostrud exercitation</a>
				</div>
			</v-alert>
		</v-container>
	</div>
`;

// export template
export default template;
