/**
 * SclSubscribePanel api
 *
 */

// template
const template = `
	<div>
		<v-container>
			<v-layout row wrap>
				<v-flex xs12 md6>
					<h5 class="scl-subscribe-panel__title">Subscribe</h5>
					<h3 class="scl-subscribe-panel__sub-title">Sign up to our newsletter</h3>
					<scl-subscribe-form inline-template class="scl-component scl-subscribe-form scl-subscribe-form__variant"
					:api="{
						variant: true
					}"
					>
						<v-form v-if="propApi.variant && !success" v-model="valid" ref="subscribeForm">
							<p class="scl-subscribe-form__variant--description">Get the latest business and industry news delivered direct. We promise we won’t overload your inbox.</p>
							<div class="scl-subscribe-form__variant--wrapper">
								<v-text-field
									v-model="emailAddress"
									:rules="emailRules"
									:validate-on-blur="true"
									placeholder="Enter email"
									class="scl-component scl-input scl-subscribe-form__variant--input"
									required
								></v-text-field>
								<v-btn depressed class="scl-component scl-button scl-subscribe-form__variant--btn"
									:outline="false"
									:class="{'scl-button--outline' : propApi.outline }"
									@click="subscribe"
								>
									Subscribe <v-icon class="scl-button__icon scl-subscribe-form__variant--icon">fal fa-paper-plane</v-icon>
								</v-btn>
							</div>
							<span class="error--text" v-if="!success && error">@Html.Glass().Editable(Model, n => n.Error_Message)</span>
							<p class="scl-subscribe-form__variant--terms">By subscribing to Coates you agree to our <a :href="$root.dummyUrl" class="scl-subscribe-form__variant--terms-link">{{ $root.dummyWord }}</a></p>
						</v-form>
						<div v-else-if="propApi.variant && success">
							<p class="scl-subscribe-form__variant--success-message">Thank you.<p>
						</div>

						<v-form v-else v-model="valid">
							<v-container>
								<v-layout row wrap>
									<v-flex xs12 md3>
										<div class="scl-subscribe-form__container">
											<v-icon class="scl-subscribe-form__icon">fas fa-envelope</v-icon>
											<p>Sign up to receive our latest news and special offers</p>
										</div>
									</v-flex>
									<v-flex xs12 md9>
										<div class="scl-subscribe-form__container">
											<v-text-field
												v-model="emailAddress"
												:rules="emailRules"
												placeholder="Email Address"
												class="scl-component scl-input"
												required
											></v-text-field>
											<scl-button inline-template :api="{ outline: false, copy: 'subscribe', type: 'submit' }">
												<v-btn depressed class="scl-component scl-button"
													:href="propApi.href"
													:outline="propApi.outline"
													:target="propApi.target"
													:class="{'scl-button--outline' : propApi.outline }">
													{{ propApi.copy }} <v-icon v-if="propApi.icon" class="scl-button__icon">{{ propApi.icon }}</v-icon>
												</v-btn>
											</scl-button>
										</div>
									</v-flex>
								</v-layout>
							</v-container>
						</v-form>
					</scl-subscribe-form>
				</v-flex>
				<v-flex xs12 offset-md1 md5>
					<v-img
						src="https://picsum.photos/636/358"
						class="scl-subscribe-panel__image"
					></v-img>
				</v-flex>
			</v-layout>
		</v-container>
	</div>
`;

// export template
export default template;
