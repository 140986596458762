/**
 * SclCard api
 *
 * overlayTextColour prop
 * @param {string} 'white--text'
 * @param {string} 'black--text'
 *
 * variant prop
 * @param {string} 'dark'
 * @param {string} 'light'
 */

// template
const template = `
	<scl-card-horizontal inline-template :api="{ overlayTextColour: 'white--text', variant: 'light' }" class="scl-component scl-card scl-card--horizontal">
		<v-layout>
			<v-flex pa-4>
				<v-card :dark="propApi.variant === 'dark'" :light="propApi.variant === 'light'">
					<v-layout :column="$vuetify.breakpoint.mdAndDown">
						<v-flex xs12 md6>
							<v-img :lazy-src="$root.dummyImage43" :src="$root.dummyImage43" aspect-ratio="1.33" :class="propApi.overlayTextColour"/>
						</v-flex>

						<v-flex xs12 md6>
							<v-card-title>
								<div>
									<h3 class="headline mb-3">{{$root.dummyWord}}</h3>
									<div>
										<p>{{$root.dummyParagraph}}</p>
									</div>
								</div>
							</v-card-title>
							<v-card-actions>
								<v-btn flat color="blue" href="#">{{$root.dummyWords}}</v-btn>
							</v-card-actions>
						</v-flex>
					</v-layout>
				</v-card>
			</v-flex>
		</v-layout>
	</scl-card-horizontal>
`;

// export template
export default template;
