// css import
import('./_scl-carousel-testimonial.scss');

// component
const component = {
	name: 'SclCarouselTestimonial',
	template: null,
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.cycle ? typeof obj.cycle === 'boolean' : true));
				set.add(!!(obj.delimiterIcon ? typeof obj.delimiterIcon === 'string' : true));
				set.add(!!(obj.hideDelimiters ? typeof obj.hideDelimiters === 'boolean' : true));
				set.add(!!(obj.hideControls ? typeof obj.hideControls === 'boolean' : true));
				set.add(!!(obj.height ? typeof obj.height === 'number' : true));
				set.add(!!(obj.interval ? typeof obj.interval === 'number' : true));
				set.add(!!(obj.nextIcon ? typeof obj.nextIcon === 'string' : true));
				set.add(!!(obj.prevIcon ? typeof obj.prevIcon === 'string' : true));
				return !set.has(false);
			}
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					cycle: true,
					delimiterIcon: this.$vuetify.icons.delimiter,
					hideDelimiters: false,
					hideControls: false,
					height: 768,
					interval: 5000,
					nextIcon: this.$vuetify.icons.next,
					prevIcon: this.$vuetify.icons.prev
				},
				this.api
			);
		}
	}
};

// set template if storybook environment variable string
if (process.env.VUE_APP_DEVELOPMENT) {
	component.template = require(`./${component.name}-template`).default;
}

export default component;
