/*
 * Mock / Dummy data mixin
 * Used to populate components with content in development mode
 */

const DummyData = {
	data() {
		return {
			dummyIcon: 'fas fa-skull',
			dummyImage43: 'https://picsum.photos/800/600',
			dummyImage169: 'https://picsum.photos/1920/1080',
			dummyImageSquare: 'https://picsum.photos/768',
			dummyNumber: '5',
			dummyNumbers: '80',
			dummyNextIcon: 'fas fa-chevron-circle-right',
			dummyPrevIcon: 'fas fa-chevron-circle-left',
			dummyParagraph:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
			dummySentence: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
			dummyWord: 'lorem',
			dummyWords: 'lorem ipsum dolor',
			dummyUrl: 'https://www.switchit.com/',
			dummyCurator: `<!-- Place <div> tag where you want the feed to appear -->
				<div id="curator-feed-facebook"><a href="https://curator.io" target="_blank" class="crt-logo crt-tag">Powered by Curator.io</a></div>
				<!-- The Javascript can be moved to the end of the html page before the </body> tag -->
				<script type="text/javascript">
				/* curator-feed-facebook */
				(function(){
				var i, e, d = document, s = "script";i = d.createElement("script");i.async = 1;
				i.src = "https://cdn.curator.io/published/f4243740-5407-44b2-8a30-04b665d449ad.js";
				e = d.getElementsByTagName(s)[0];e.parentNode.insertBefore(i, e);
				})();
				</script>`,
			dummyDate: '14 Dec 2020'
		};
	}
};

export default DummyData;
