/**
 * SclCardSolid api
 *
 * href prop
 * @param {string} 'http://www.example.com'
 *
 * img prop
 * @param {string} 'https://picsum.photos/700/965'
 *
 * title prop
 * @param {string} 'Lorem ipsum dolor'
 *
 * icon prop
 * @param {string} 'fas fa-truck-pickup'
 *
 * desc prop
 * @param {string} 'Lorem ipsum dolor'
 */

// template
const template = `
	<div>
		<v-card flat :href="propApi.href" class="scl-card-solid__card">
			<v-img v-if="!propApi.desc" :src="propApi.img" aspect-ratio="1" class="scl-card-solid__img"></v-img>
			<div class="scl-card-solid__panel">
				<div class="scl-card-solid__panel-container">
					<v-icon color="white" class="scl-card-solid__icon icon-ch-01"></v-icon>
					<h4 class="scl-card-solid__title">{{ propApi.title }}</h4>
					<p v-if="$vuetify.breakpoint.mdAndUp" class="scl-card-solid__desc">{{ propApi.desc }}</p>
				</div>
			</div>
		</v-card>
	</div>
`;

// export template
export default template;
