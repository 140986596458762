/**
 * SclButton api
 *
 * href prop
 * @param {string} 'http://www.example.com'
 *
 * outline prop
 * @param {boolean} true
 *
 * copy prop
 * @param {string} 'lorem ipsum'
 *
 * icon prop
 * @param {string} 'fas fa-lock'
 * @param {string} 'fas fa-arrow-right'
 *
 * type prop
 * @param {string} 'submit'
 *
 * target prop
 * @param {string} '_blank'
 */

// template
const template = `
	<v-btn depressed :href="propApi.href" :outline="propApi.outline" :target="propApi.target" :class="{'scl-button--outline' : propApi.outline }">
		{{ propApi.copy }} <v-icon v-if="propApi.icon" class="scl-button__icon">{{ propApi.icon }}</v-icon>
	</v-btn>
`;

// export template
export default template;
