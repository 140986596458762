/**
 * SclCardLinkSelector api
 *
 * href prop
 * @param {string} 'http://www.example.com'
 *
 * title prop
 * @param {string} 'Lorem ipsum dolor sit amet consectetur adipiscing'
 *
 * subtitle prop
 * @param {string} 'Lorem ipsum'
 *
 * tag prop
 * @param {string} 'image tag'
 *
 * dropdown prop
 * @param {string} [{copy: 'dropdown name', href: 'http://www.example.com'}]
 *
 * img prop
 * @param {string} 'https://picsum.photos/700/965'
 */

// template
const template = `
	<div class="scl-card-link-selector">
		<v-card flat class="scl-card-link-selector__card">
			<v-img src="https://picsum.photos/768/384" class="scl-card-link-selector__image"  aspect-ratio="2">
				<div class="scl-card-link-selector__image-tag">
					<p class="scl-card-link-selector__image-tag-name">Tag</p>
				</div>
			</v-img>

			<div class="scl-card-link-selector__content">
				<h4 class="scl-card-link-selector__title">Title</h4>
				<p class="scl-card-link-selector__description">{{ $root.dummyParagraph}}</p>				
				</div>
				<div class="scl-card-link-selector__link">
				<v-select
					v-model="selectedLink"
					outline
					:menu-props="{ bottom: true, offsetY: true }"
					:items="propApi.dropDownSelector"
					item-text="title"
					item-value="url"
					append-icon="fas fa-chevron-down"
					class="scl-card-link-selector__selection"
					single-line
					@change="selectChange"
				></v-select>
					<scl-button class="scl-component scl-button" :api="primaryBtnApi" inline-template>
						<v-btn depressed class="scl-component scl-button scl-button--tertiary "
							:href="propApi.href"
							:outline="propApi.outline"
							:class="{'scl-button--outline' : propApi.outline}">
							{{ propApi.copy }} <v-icon class="scl-button__icon">{{ propApi.icon }}</v-icon>
						</v-btn>
					</scl-button>
				</div>
			<div class="scl-card-link-selector__card-hover"></div>
		</v-card>
	</div>
`;

// export template
export default template;
