// css import
require('./_SclTabCarousel.scss');

// component
const component = {
	name: 'SclTabCarousel',
	data() {
		return {
			activeCarousel: 0,
			activeTab: 'tab-0',
			carouselHeight: 0
		};
	},
	props: {
		api: {
			type: Object,
			required: false,
			// props type validation
			validator: obj => {
				const set = new Set();
				set.add(!!(obj.centered ? typeof obj.centered === 'boolean' : true));
				set.add(!!(obj.grow ? typeof obj.grow === 'boolean' : true));
				set.add(!!(obj.hideSlider ? typeof obj.hideSlider === 'boolean' : true));
				set.add(!!(obj.icons ? typeof obj.icons === 'boolean' : true));
				set.add(!!(obj.right ? typeof obj.right === 'boolean' : true));
				set.add(!!(obj.disableTouch ? typeof obj.disableTouch === 'boolean' : true));
				set.add(!!(obj.overflowArrows ? typeof obj.overflowArrows === 'boolean' : true));
				return !set.has(false);
			}
		}
	},
	mounted() {
		this.handleCarouselHeight();
	},
	destroyed() {
		window.removeEventListener('resize', this.handleCarouselHeight);
	},
	watch: {
		activeTab(position) {
			this.activeCarousel = parseInt(position.replace('tab-', ''));
		},
		activeCarousel(position) {
			var activeTab = position;
			this.activeTab = `tab-${activeTab}`;
		}
	},
	computed: {
		// props defaults
		propApi() {
			return Object.assign(
				{
					centered: false,
					grow: false,
					hideSlider: false,
					icons: false,
					right: false,
					disableTouch: true,
					overflowArrows: false
				},
				this.api
			);
		},
		setMargin() {
			this.handleCarouselHeight();
			const margin = `${this.$root.headerHeight}px`;
			return margin;
		},
		getHeight() {
			return this.$root.windowWidth >= 1024 && this.$root.windowHeight >= 768;
		}
	},
	methods: {
		handleCarouselHeight() {
			window.addEventListener('resize', this.handleCarouselHeight);
			const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
			const headerHeight = this.$root.headerHeight;
			this.carouselHeight = vh - headerHeight;
		}
	},
	template: null
};

// set template and dummy data if development environment variable string
component.template = require(`./${component.name}-template`).default;

// export component
export default component;
